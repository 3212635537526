import { isAnyOf } from "@reduxjs/toolkit";

import { __GLOBAL_BROWSER_STORAGE, __GLOBAL_TENANT } from "@/client/globals";
import { AppStartListening } from "@/data/listenerMiddleware";
import { AppDispatch } from "@/data/store";
import {
  goNextPage,
  goPrevPage,
  goToPage,
  PageInfo,
  selectCurrentPage,
} from "@/features/answer";
import {
  selectAssessmentFormat,
  selectIsExam,
  selectIsLockDownExam,
} from "@/features/assignment";
import {
  hydrateSnapshots,
  selectAnswerSnapshot,
  selectSnapshots,
  setLocalSnapshot,
  setPendingSnapshot,
} from "@/features/authority";
import { BrowserStorageState } from "@/features/browser-storage";
import { QuestionType, TaskFormat } from "@/generated/graphql";
import { setItem } from "@/utils/localStorage";
import { withTimeout } from "@/utils/with-timeout";

import {
  answerStoreLoaded,
  clearActiveEditor,
  loadAnswerStore,
} from "../answer-store";
import { Snapshot } from "../snapshot";

/**
 * Start listeners firing effects related to the Answer slice.
 */
export function startAnswerListeners(startListening: AppStartListening) {
  // Set of answer blocks that have changed, passed as a mutable dependency to
  // the listener
  const CHANGED_ANSWER_BLOCKS: Set<string> = new Set();

  startAnswerStoreListener(startListening);
  startPageNavigationListener(startListening);
  startLocalSnapshotListener(startListening, CHANGED_ANSWER_BLOCKS);
}

/**
 * Start listener for hydrating the answer store after all the snapshots are
 * loaded into the Redux store.
 *
 * isRecoveryCompleted - flag to make sure offline recovery is done only once in
 * tha app lifecycle
 */
export function startAnswerStoreListener(startListening: AppStartListening) {
  let isRecoveryCompleted = false;
  startListening({
    actionCreator: hydrateSnapshots,
    effect: async (action, listenerApi) => {
      const { answers, assessmentName } = action.payload;
      const snapshots = selectSnapshots(listenerApi.getState());

      if (!isRecoveryCompleted) {
        await handleOfflineRecovery(snapshots, listenerApi.dispatch);
        isRecoveryCompleted = true;
      }

      const isLockDownExam = selectIsLockDownExam(listenerApi.getState());
      const isExam = selectIsExam(listenerApi.getState());
      const isMultiformat =
        selectAssessmentFormat(listenerApi.getState()) ===
        TaskFormat.Multiformat;

      const referencingStyle =
        listenerApi.getState().assignment.referencingStyle;

      // making sure the answer store is loaded with the latest snapshots
      // (since offline recovery could have updated them)
      const latestSnapshots = selectSnapshots(listenerApi.getState());
      loadAnswerStore(answers, latestSnapshots, listenerApi.dispatch, {
        isExam,
        isMultiformat,
        isLockDownExam,
        referencingStyle,
        assessmentName,
      });

      answerStoreLoaded(true);
    },
  });
}

async function handleOfflineRecovery(
  snapshots: Record<string, Snapshot>,
  dispatch: AppDispatch
) {
  const storage = __GLOBAL_BROWSER_STORAGE.current;
  if (!storage) return;

  const initializeAndRecover = async () => {
    if (storage.getState() === BrowserStorageState.NOT_INITIALISED) {
      await storage.initialise();
    }
    await storage.recoverPendingSnapshots(snapshots, dispatch);
  };

  const TIMEOUT_MS = 10000;
  try {
    await withTimeout(initializeAndRecover(), TIMEOUT_MS);
  } catch (error) {
    console.error("Browser Storage initialisation timed out");
    storage.disable();
  }
}

/**
 * Start listener for answer page navigation events. Following effects are
 * fired:
 *
 *   1. The `state.answer.currentPageIndex` is saved to localStorage.
 *
 *   2. The current active editor is cleared when the question type is not an
 *      extended answer.
 */
export function startPageNavigationListener(startListening: AppStartListening) {
  // Listen to the change of answer page, clear active editor when question type is
  // not extended type.
  startListening({
    matcher: isAnyOf(goPrevPage, goNextPage, goToPage),
    effect: (_action, listenerApi) => {
      const state = listenerApi.getState();

      // Save currentPageIndex to localStorage
      if (state.authority.workId) {
        setItem(
          state.authority.workId,
          "currentPageIndex",
          JSON.stringify(state.answer.currentPageIndex)
        );
      }

      // Clear active editor if the current answer is not an extended answer
      const currentPage: PageInfo = selectCurrentPage(state);
      if (currentPage.page === "answer") {
        if (currentPage.questionType !== QuestionType.Extended) {
          clearActiveEditor();
        }
      }
    },
  });
}

/**
 * Track Answer Blocks that have changed and queue their latest snapshot for
 * saving after a debounce interval.
 *
 * Listen to `setLocalSnapshot` actions which set the local snapshot states on
 * every change. It will globally keep track of the set of Answer Block IDs that
 * have changed. The effect will "debounce" and eventually push the latest
 * snapshot for each changed Answer Block to the pending snapshots queue.
 *
 * @param startListening Redux listener middleware's start function
 * @param changedBlockIds Mutable set to track which answer blocks have changed over
 *   the course of the listener's lifetime.
 */
export function startLocalSnapshotListener(
  startListening: AppStartListening,
  changedBlockIds: Set<string>,
  delayMs = 200
) {
  startListening({
    actionCreator: setLocalSnapshot,
    effect: async (action, listenerApi) => {
      changedBlockIds.add(action.payload.answerBlockId);

      // Cancel any in-progress instances of this listener
      listenerApi.cancelActiveListeners();
      // Delay before starting actual work
      await listenerApi.delay(delayMs);

      const state = listenerApi.getState();
      for (const answerBlockId of changedBlockIds.keys()) {
        const snapshot = selectAnswerSnapshot(state, answerBlockId);
        if (snapshot) {
          listenerApi.dispatch(
            setPendingSnapshot({
              answerBlockId,
              snapshot,
            })
          );
        }
      }

      changedBlockIds.clear();
    },
  });
}
