import { styled } from "styled-components";

import { Icon, Text } from "@vericus/cadmus-ui";

import { calcPercentage } from "utils/math";

interface Props {
  /**
   * Submission Date
   */
  submissionDate: Date;
  /**
   * `undefined` means no draft
   * while `false` means not yet submitted
   */
  draft?: boolean;
  final: boolean;
  releasedDate: Date;
  draftDue: Date | null;
  dueDate: Date;

  // time limited assessment
  endDate: Date | null;
  startDate: Date | null;
}

/**
 * Displays a progress bar that indicates the progression of the assessment.
 *
 * Under a normal assessment, Timeline will display the progress of draft and
 *  final due date relative to instruction release date.
 *
 * Under a time limited assessment, Timeline will display the working duration
 *  relative to the time limit.
 */
export function Timeline(props: Props) {
  const {
    submissionDate,
    draft,
    final,
    releasedDate,
    draftDue,
    dueDate,
    endDate,
    startDate,
  } = props;

  // time limited assessment
  if (startDate !== null && endDate !== null) {
    const { finalProgress } = calcProgress(
      startDate,
      null,
      endDate,
      submissionDate
    );

    return (
      <ProgressBar
        finalProgress={finalProgress}
        hasFinal={final}
        finalText="Final"
      />
    );
  }

  // normal assessment
  const { stepProgress, finalProgress } = calcProgress(
    releasedDate,
    draftDue,
    dueDate,
    submissionDate
  );
  const ratio = draftDue && calcStepRatio(releasedDate, draftDue, dueDate);

  const step =
    draft !== undefined && stepProgress !== null && ratio !== null
      ? {
          progress: stepProgress,
          ratio,
          hasStep: draft,
          text: "Draft",
        }
      : undefined;

  return (
    <ProgressBar
      finalProgress={finalProgress}
      hasFinal={final}
      finalText="Final"
      step={step}
    />
  );
}

interface ProgressBarProps {
  finalProgress: number;
  hasFinal: boolean;
  finalText: string;

  /**
   * Optional step in between the start and final.
   * In a normal assessment, this is draft.
   */
  step?: {
    progress: number;
    ratio: number;
    hasStep: boolean;
    text: string;
  };
}

/**
 * Chart component of `Timeline`
 */
const ProgressBar = (props: ProgressBarProps) => {
  const { finalProgress, hasFinal, finalText, step } = props;

  return (
    <Container>
      {step && (
        <>
          <Bar progress={step.progress} ratio={step.ratio} />
          <StepLabel checked={step.hasStep} text={step.text} />
        </>
      )}
      <Bar progress={finalProgress} />
      <Label checked={hasFinal} text={finalText} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
`;

interface LabelProps {
  checked: boolean;
  text: string;
  className?: string;
}

const Label = ({ checked, text, className }: LabelProps) => (
  <LabelContainer className={className} checked={checked}>
    <Text kind="label" color={checked ? "shade1" : "shade2"}>
      <Icon
        iconName={checked ? "Tick" : "Close"}
        iconColor={checked ? "grey500" : "grey400"}
      />{" "}
      {text}
    </Text>
  </LabelContainer>
);

const LabelContainer = styled.div<{ checked: boolean }>`
  width: fit-content;
  padding-left: 9px;
  display: flex;
`;

const StepLabel = styled(Label)`
  padding-right: 9px;
`;

// Bar takes an optional progress, which creates the the progress
const Bar = styled.div.attrs<{ progress: number; ratio?: number }>((p) => ({
  style: {
    background: `linear-gradient(to right,
      ${p.theme.text.shade1} ${p.progress}%,
      ${p.theme.background.action2} ${p.progress}%
    )`,
  },
}))<{ progress: number; ratio?: number }>`
  height: 2px;
  border-radius: 1px;
  flex: auto;
  flex-grow: ${(p) => (p.ratio === undefined ? "1" : p.ratio)};
`;

// ------------------------- helper functions -------------------------

/**
 * Calculates progress relative to step and final dates.
 */
export const calcProgress = (
  start: Date,
  step: Date | null,
  final: Date,
  now: Date
): { stepProgress: number | null; finalProgress: number } => {
  if (step) {
    const stepProgress = calcPercentage(+now - +start, +step - +start);
    const finalProgress = calcPercentage(+now - +step, +final - +step);

    return { stepProgress, finalProgress };
  } else {
    const finalProgress = calcPercentage(+now - +start, +final - +start);

    return {
      stepProgress: null,
      finalProgress,
    };
  }
};

/**
 * Calculates the ratio of step duration relative to final duration
 */
export const calcStepRatio = (
  released: Date,
  step: Date,
  final: Date
): number => {
  const den = +final - +step;
  if (den === 0) return 1;

  const num = +step - +released;
  if (num <= 0) return 0;

  return num / den;
};
