import { Duration, formatDuration } from "date-fns";

/**
26 hours -> 1 day
24 hours 1 min -> 1 day
24 hours -> 24 hours // round up after this
23 hours 59 min -> 24 hours
23 hours 1 min -> 24 hours
12 hours 59 min -> 13 hours
12 hours 1 min -> 13 hours
12 hours -> 12 hours
11 hours 59 min 59 s -> 12 hours
11 hours 59 min -> 11 hours 59 min
9 hours 59 minutes -> 9 hours 59 min
1 hour 1 min -> 1 hour 1 min
1 hour 30 sec -> 1 hour 1 min
1 hour -> 1 hour
59 min -> 59 min
2 min -> 2 min
1 min 59 sec -> 1 min 59 sec
**/

const MILLISECONDS_MINUTE = 60 * 1000;
const MILLISECONDS_HOUR = 60 * MILLISECONDS_MINUTE;
const MILLISECONDS_DAY = 24 * MILLISECONDS_HOUR;

/**
 * Format a millisecond interval.
 */
export function formatMilliseconds(milliseconds: number): string {
  const formatted = formatDuration(millisecondsDuration(milliseconds));
  if (formatted === "") {
    return "0 seconds";
  }
  return formatted;
}

/**
 * Break a `milliseconds` amount into a formattable Duration.
 */
export function millisecondsDuration(milliseconds: number): Duration {
  if (milliseconds <= 0) {
    return {};
  }

  // Round the milliseconds to seconds
  milliseconds = Math.round(milliseconds / 1000) * 1000;

  if (milliseconds > MILLISECONDS_DAY) {
    const days = Math.floor(milliseconds / MILLISECONDS_DAY);
    return { days };
  }

  if (milliseconds > 12 * MILLISECONDS_HOUR) {
    const hours = Math.ceil(milliseconds / MILLISECONDS_HOUR);
    return { hours };
  }

  if (milliseconds >= MILLISECONDS_HOUR) {
    const hours = Math.floor(milliseconds / MILLISECONDS_HOUR);
    const minutes = Math.floor(
      (milliseconds % MILLISECONDS_HOUR) / MILLISECONDS_MINUTE
    );
    return { hours, minutes };
  }

  if (milliseconds >= 2 * MILLISECONDS_MINUTE) {
    const hours = Math.floor(milliseconds / MILLISECONDS_HOUR);
    const minutes = Math.ceil(
      (milliseconds % MILLISECONDS_HOUR) / MILLISECONDS_MINUTE
    );
    return { hours, minutes };
  }

  if (milliseconds >= MILLISECONDS_MINUTE) {
    const minutes = Math.floor(
      (milliseconds % MILLISECONDS_HOUR) / MILLISECONDS_MINUTE
    );
    const seconds = Math.ceil((milliseconds % MILLISECONDS_MINUTE) / 1000);
    return { minutes, seconds };
  }

  const seconds = Math.floor(milliseconds / 1000);
  return { seconds };
}
