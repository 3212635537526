import { useId, useMemo } from "react";
import { styled } from "styled-components";

import {
  Checkbox,
  colors,
  ErrorMessage,
  LinkButton,
  Text,
} from "@vericus/cadmus-ui";

import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer";
import { BLOCKS, Document, INLINES } from "@contentful/rich-text-types";

const SUBMISSION_DECLARATION_URL =
  "https://support.cadmus.io/students/submission-declaration";

export interface AcademicIntegrityAgreementProps {
  /**
   * Whether the student has agreed to the pledge.
   */
  isChecked?: boolean;
  /**
   * The assessment is an exam.
   */
  isExam?: boolean;
  /**
   * Show an error if the pledge is not checked.
   */
  showError?: boolean;
  /**
   * Show a header.
   */
  withHeader?: boolean;

  /**
   * Use the short version of the pledge.
   */
  withShortenedPledge?: boolean;

  /**
   * Custom contentful Document to use as the custom declaration.
   */
  customDeclarationText?: string;

  /**
   * Callback to update the checked value of the agreement.
   */
  onUpdateAgreement: (hasAgreed: boolean) => void;
}

// Contentful React renderer for the declaration Document.
const customDeclarationTextRenderer: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_node, children) => <>{children}</>,
    [INLINES.HYPERLINK]: (node, children) => {
      const link = node.data.uri;
      return (
        <LinkButton
          inline
          onClick={() => {
            const win = window.open(link, "_blank");
            if (win) {
              win.focus();
            }
          }}
        >
          {children}
        </LinkButton>
      );
    },
  },
};

/**
 * Allow students to access and agree, or disagree, to the academic integrity
 * pledge.
 */
export const AcademicIntegrityAgreement = ({
  withHeader,
  isChecked,
  isExam,
  showError,
  withShortenedPledge,
  customDeclarationText,
  onUpdateAgreement,
}: AcademicIntegrityAgreementProps) => {
  const pledgeId = useId(); // for a11y

  const customDeclaration = useMemo(() => {
    if (customDeclarationText) {
      try {
        const doc: Document = JSON.parse(customDeclarationText);
        return documentToReactComponents(doc, customDeclarationTextRenderer);
      } catch (error) {
        console.error(error);
        return null;
      }
    } else {
      return null;
    }
  }, [customDeclarationText]);

  return (
    <Container data-testid="academic-integrity-agreement">
      <AgreementContainer showError={showError}>
        {withHeader && (
          <AgreementHeader>
            <Text kind="label" textAlign="center">
              Academic integrity agreement
            </Text>
          </AgreementHeader>
        )}
        <AgreementCheckbox
          aria-labelledby={pledgeId}
          checked={isChecked}
          onChange={(e) => {
            onUpdateAgreement(e.target.checked);
          }}
        >
          {customDeclaration ? (
            <Text id={pledgeId} kind="bodySm" style={{ marginTop: 2 }}>
              <>{customDeclaration}</>
            </Text>
          ) : (
            <Text id={pledgeId} kind="bodySm" style={{ marginTop: 2 }}>
              {withShortenedPledge ? (
                <>I declare this work is my own, as per the </>
              ) : (
                <>
                  By completing and submitting this{" "}
                  {isExam ? "exam" : "assignment"}, I confirm that all work here
                  is my own, in adherence to the{" "}
                </>
              )}
              <LinkButton
                inline
                onClick={() => {
                  const win = window.open(SUBMISSION_DECLARATION_URL, "_blank");
                  if (win) {
                    win.focus();
                  }
                }}
              >
                submission declaration
              </LinkButton>
            </Text>
          )}
        </AgreementCheckbox>
      </AgreementContainer>
      {showError && (
        <ErrorMessage fullWidth position="relative">
          <Text kind="systemSm" color="inherit" style={{ marginTop: -2 }}>
            You must review and accept the submission declaration in order to
            submit
          </Text>
        </ErrorMessage>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  text-align: left;
`;

const AgreementContainer = styled.div<{ showError?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: baseline;

  background-color: ${colors.grey50};
  border: 1px solid transparent;
  border-color: ${(p) => (p.showError ? colors.red500 : "transparent")};
  border-radius: 3px;
  box-sizing: border-box;
`;

const AgreementCheckbox = styled(Checkbox)`
  align-items: flex-start;
  padding: 24px;
  user-select: none;
  cursor: pointer;

  & > span {
  }
`;

const AgreementHeader = styled.div`
  padding-top: 20px;
  text-align: center;
  width: 100%;
`;
