import { useEffect, useState } from "react";

import * as Comlink from "comlink";

import { useAppDispatch } from "@/data/hooks";
import { addAppListener } from "@/data/listenerMiddleware";
import { onFocus } from "@/data/setup-dom-listeners";
import { intervalInMs } from "@/utils/datetime";

import { CountdownMessage, newCountdownMessage } from "./countdown-message";
import { CountdownProxy } from "./countdown-proxy";

/**
 * Create a countdown message to an end date.
 *
 * @param end ISO8601 date string until which the countdown ticks.
 * @returns a formatted message describing the distance to the end date.
 */
export function useCountdown(end: string): CountdownMessage {
  const dispatch = useAppDispatch();

  const [milliseconds, setMilliseconds] = useState(() =>
    intervalInMs(new Date(end))
  );

  const [message, setMessage] = useState<CountdownMessage>(() =>
    newCountdownMessage(milliseconds)
  );

  useEffect(() => {
    // Async setup or update of the current poller instance Proxy
    async function connect({ signal }: { signal: AbortSignal }) {
      if (signal.aborted || milliseconds < 0) {
        return undefined;
      }

      const callback = (msg: CountdownMessage) => setMessage(msg);
      const callbackProxy = Comlink.proxy(callback);
      const instance = await new CountdownProxy(callbackProxy, milliseconds);

      if (!signal.aborted) {
        await instance.tick();
      }

      signal.onabort = async () => {
        await instance.stop();
      };
    }

    // Connect or update the timer state
    const controller = new AbortController();
    connect({ signal: controller.signal });

    const unsubscribe = dispatch(
      addAppListener({
        actionCreator: onFocus,
        effect: () => {
          setMilliseconds(intervalInMs(new Date(end)));
        },
      })
    );

    return () => {
      controller.abort();
      unsubscribe();
    };
  }, [end, milliseconds, setMessage, dispatch]);

  return message;
}
