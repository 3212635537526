import { isHangingIndent } from "@vericus/cadmus-common";

import client from "client/apollo";
import {
  AnswerBlockFragment,
  AnswerBlockFragmentDoc,
  BlockSnapshotFragment,
  BlockSnapshotFragmentDoc,
  RequirementsFragment,
  SaveFragment,
  SaveFragmentDoc,
  SubmissionMetadataFragment,
  SubmissionMetadataFragmentDoc,
  SubmissionType,
  WorkAnswerBlocksFragment,
  WorkAnswerBlocksFragmentDoc,
} from "generated/graphql";

/**
 * Check if the given Sheet requirements specify hanging indent style
 * references.
 */
export function selectHangingIndent(reqs: RequirementsFragment) {
  return isHangingIndent(reqs.referencingStyle);
}

/**
 * Select the required Referencing Style from the given requirements or default
 * to `APA`.
 */
export function selectReferencingStyle(reqs: RequirementsFragment | null) {
  return reqs && reqs.referencingStyle ? reqs.referencingStyle : "APA";
}

/**
 * Deprecating in favour of answer blocks.
 * Read the latest `Save` value from the cache.
 *
 * This is only used to keep track of the save ID references.
 */
export function readLatestSave(): SaveFragment | null {
  return client.readFragment({
    id: "Save:latest_save",
    fragment: SaveFragmentDoc,
    fragmentName: "Save",
  });
}

/**
 * List all AnswerBlocks and their latest snapshots in the singleton Work.
 */
export function readAnswerBlocks(): AnswerBlockFragment[] {
  const result = client.readFragment<WorkAnswerBlocksFragment>({
    id: `Work:{}`,
    fragment: WorkAnswerBlocksFragmentDoc,
    fragmentName: "WorkAnswerBlocks",
  });
  return result?.answerBlocks ?? [];
}

/**
 * Read the AnswerBlock with the given ID from the cache.
 */
export function readAnswerBlock(
  answerBlockId: string
): AnswerBlockFragment | null {
  return client.readFragment({
    id: `AnswerBlock:${answerBlockId}`,
    fragment: AnswerBlockFragmentDoc,
    fragmentName: "AnswerBlock",
  });
}

/**
 * Read the latest known BlockSnapshot fragment for an AnswerBlock.
 *
 * Latest BlockSnapshot objects are stored with a fixed ID in the Apollo cache
 * with the pattern `${ANSWER_BLOCK_ID}:latest_snapshot`.
 */
export function readLatestBlockSnapshot(
  answerBlockId: string
): BlockSnapshotFragment | null {
  return client.readFragment({
    id: `BlockSnapshot:{"cacheId":"${answerBlockId}:latest_snapshot"}`,
    fragment: BlockSnapshotFragmentDoc,
    fragmentName: "BlockSnapshot",
  });
}

/**
 * Read a `Submission` value of the given type from the cache.
 */
export function readSubmissionMetadata(
  typ: SubmissionType
): SubmissionMetadataFragment | null {
  return client.readFragment({
    id: `Submission:{"type":"${typ}"}`,
    fragment: SubmissionMetadataFragmentDoc,
    fragmentName: "SubmissionMetadata",
  });
}

/**
 * List all AnswerBlocks but with their latest snapshot replaced with the
 * matching snapshot from `snapshots`.
 *
 * If an AnswerBlock does not have a matching snapshot, its `latestSnapshot` is
 * set to null.
 *
 * Useful to match submitted Block Snapshots with the current list of Answer
 * Blocks loaded in cache.
 */
export function readAnswerBlocksWithSnapshots(
  snapshots: BlockSnapshotFragment[]
): AnswerBlockFragment[] {
  const answerBlocks = readAnswerBlocks();
  return snapshots.flatMap((snapshot) => {
    const answerBlock = answerBlocks.find(
      (blk) => blk.id === snapshot.answerBlockId
    );
    if (answerBlock) {
      return [
        {
          ...answerBlock,
          latestBlockSnapshot: snapshot,
        },
      ];
    }
    return [];
  });
}
