import { styled, keyframes } from "styled-components";

import { Text } from "@vericus/cadmus-ui";

import Sprite from "./loading-sprite.png";

const sprite = keyframes`
  from {
    background-position: 0px;
  }
  to {
    background-position: -5400px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 16px;
`;

const Quill = styled.div`
  width: 180px;
  height: 81px;
  overflow: hidden;
  background-image: url(${Sprite});
  animation: ${sprite} 0.5s steps(30) infinite alternate;
  margin-left: -16px;
`;

const LoadingText = styled(Text)`
  text-transform: uppercase;
  font-weight: 600;
`;

/**
 *
 * A full page loading indicator. Uses a css animated spritesheet.
 */
export const LoadingQuill = ({ message }: { message?: string }) => (
  <Container>
    <Quill />
    {message ? (
      <>
        <LoadingText kind="label">{message}</LoadingText>
      </>
    ) : null}
  </Container>
);
