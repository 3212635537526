import seedrandom from "seedrandom";

/**
 * Shuffle a given array based on seed
 */
export function shuffleWithSeed<T>(
  /** Array to shuffle */
  arr: T[],
  /** Random seed */
  seed: string
): T[] {
  const rng = seedrandom(seed);
  return shuffleArray(arr, rng);
}

/**
 * Shuffle a given array based on the given randomiser
 */
export function shuffleArray<T>(
  /** Array to shuffle */
  arr: T[],
  /** Function that will return random number */
  randomiser = () => Math.random()
): T[] {
  return arr.toSorted(() => randomiser() - 0.5);
}
