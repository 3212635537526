import { Snapshot } from "@/stores/snapshot";

export enum ConnectionStatus {
  CONNECTED = "CONNECTED",
  DISCONNECTED = "DISCONNECTED",
}

/** Types of Editor Saving mechanisms. */
export enum SavingMechanism {
  FULL = "FULL",
  BLOCK = "BLOCK",
}

export interface AuthorityState {
  /** Blanket work lock on the current session to prevent Save inconsistencies. */
  sessionLock: boolean;

  /** Saving mechanis selected. */
  savingMechanism: SavingMechanism;

  /**
   * The current work ID.
   */
  workId?: string;

  /** Work has a draft submission. */
  hasDraft: boolean;

  /** Work has a final submission. */
  hasFinal: boolean;

  /** The last save request errored out? */
  saveError: SaveError | null;

  /** Number of attempts the save request queue is at. Starts with 0. */
  saveAttempt: number;

  /** Save requests' redux requestId that are still in flights*/
  savesInFlight: string[];

  /** The current status of the connection to the server */
  connectionStatus: ConnectionStatus;

  /**
   * Whether the student has accepted the academic integrity
   * submission declaration.
   */
  hasAcceptedSubmissionDeclaration: boolean;

  /** A submit request is in flight. */
  submitLoading: boolean;
  /** The last submit request has errored out. */
  submitError: SubmitError | null;
  /** Show a submission preview page. */
  submitPreview: boolean;
  /** Show a timed submit prompt locking the submission date. */
  submitPromptOpened: string | null;

  /** Local record of all latest answer snapshots */
  snapshots: Record<string, Snapshot>;

  /** Queue of unsaved Answer blocks and their snapshots */
  pendingSnapshots: PendingSnapshot[];
}

export interface SubmitError {
  kind: "validation" | "network";
  title: string;
  detail?: string;
}

export interface SaveError {
  kind: "network" | "already_reported";
  title: string;
  detail?: string;
}

/** Snapshot belonging to an Answer Block. */
export interface PendingSnapshot {
  /** The answer block id to save the snapshot to. */
  answerBlockId: string;
  /** Snapshot state */
  snapshot: Snapshot;
}
