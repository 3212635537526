import { styled } from "styled-components";

import {
  colors,
  ParagraphSkeleton,
  RequirementsSkeleton,
  SectionLabel,
  SectionSkeleton,
  Spacer,
  Text,
} from "@vericus/cadmus-ui";

import { NetworkStatus } from "@apollo/client";
import { useAppDispatch, useAppSelector } from "data/hooks";
import {
  AssessmentFragment,
  AssessmentType,
  TaskFormat,
  useSheetQuery,
  useStartMutation,
} from "generated/graphql";
import { formatDateFull } from "utils/datetime";

import lockIllustration from "@/assets/illustrations/student-with-locked-browser.png";
import { InstitutionAIAgreement } from "@/components/acadmic-integrity-agreement";
import {
  selectAssessmentFormat,
  selectExamTotalTime,
  updateSheet,
} from "@/features/assignment";
import {
  acceptSubmissionDeclaration,
  declineSubmissionDeclaration,
  selectHasAcceptedSubmissionDeclaration,
  selectSheetLockFlags,
} from "@/features/authority";
import {
  selectExamEndDate,
  selectFinalDate,
  selectTimedWorkNotStarted,
  workStartDatePassed,
} from "@/features/timeline";

import { ConnectedRequirements } from "./ConnectedRequirements";
import { ExamStartPanel } from "./ExamStartPanel";
import { Overview } from "./Overview";
import { ResourceList } from "./ResourceList";
import { SheetContent } from "./SheetContent";
import { InstructionLabel } from "./styles";
import { TimedStartPanel } from "./TimedStartPanel";

/** Props for Sheet */
interface Props {
  assessment: AssessmentFragment;
  workId: string;
}

/**
 * Displays blocking `StartPanel` for time limited
 *  assessment that is not yet started. Otherwise, displays
 *  latest instruction sheet.
 */
export function Sheet(props: Props) {
  const { assessment, workId } = props;
  const dispatch = useAppDispatch();
  // Querying the full Instruction Sheet with content.
  const {
    loading,
    error,
    data: sheetData,
    refetch,
    networkStatus,
  } = useSheetQuery({
    variables: { workId },
    notifyOnNetworkStatusChange: true,
  });

  // Work start mutation which will refetch the Sheet once the Work is started.
  const [startWork, { loading: mutationLoading }] = useStartMutation({
    variables: { workId },
    onCompleted: ({ start }) => {
      // Trigger a Sheet refetch to get the contents
      refetch();
      // Synchronise Redux state slice storing important dates and timers
      dispatch(
        updateSheet({
          assessmentId: assessment.id,
          workStartDate: start.startDate,
          settings: sheetData?.work.settings ?? null,
          requirements: sheetData?.work.sheet ?? null,
        })
      );
      if (start.startDate) {
        // Trigger toast
        dispatch(workStartDatePassed(start.startDate));
      }
    },
  });

  const hasAgreedToSubmissionDeclaration = useAppSelector(
    selectHasAcceptedSubmissionDeclaration
  );
  const finalDate = useAppSelector(selectFinalDate);

  const workNotStarted = useAppSelector(selectTimedWorkNotStarted);
  const examEndDate = useAppSelector(selectExamEndDate);
  const examTotalMinutes = useAppSelector(selectExamTotalTime);

  const format = useAppSelector(selectAssessmentFormat);

  // Flags to control sheet panel rendering
  const { showExamLock, showSheet } = useAppSelector(selectSheetLockFlags);

  // Show loading skeleton on the initial Query Loading, Mutation Loading, and
  // the eventual query refetch loading
  if (loading || mutationLoading || networkStatus === NetworkStatus.refetch) {
    return <InstructionSkeleton assessmentName={assessment.name} />;
  }

  // IF no sheet has been released or could not be fetched
  if (error || !sheetData?.work.sheet) return <EmptyInstructions />;

  // Sheet has been fetched
  const sheet = sheetData.work.sheet;

  // Timed Assignment Start panel
  if (
    sheet.assessmentType === AssessmentType.Assignment &&
    finalDate &&
    sheet.timeLimit !== null &&
    workNotStarted
  ) {
    return (
      <TimedStartPanel
        onStartWork={() => startWork()}
        finalDate={finalDate}
        timeLimit={sheet.timeLimit}
        disabled={mutationLoading}
      />
    );
  }

  return (
    <>
      <Spacer spacing={63} />
      <InstructionsTitle kind="displayTwo">{assessment.name}</InstructionsTitle>
      <Spacer spacing={36} />
      <ConnectedRequirements
        referencingStyle={sheet.referencingStyle ?? undefined}
        wordLimit={sheet.wordLimit ?? undefined}
        countReferences={sheet.countReferences ?? undefined}
        weight={sheet.weight ?? undefined}
      />
      <Spacer spacing={45} />
      {examEndDate && showExamLock && (
        <ExamStartPanel
          onStartWork={() => startWork()}
          closeDate={examEndDate}
          totalMinutes={examTotalMinutes}
          disabled={mutationLoading}
        />
      )}
      {showSheet && (
        <>
          <AcademicIntegrityContainer>
            <SectionLabel title="Academic integrity agreement" />
            <InstitutionAIAgreement
              isChecked={hasAgreedToSubmissionDeclaration}
              isExam={sheet.assessmentType === AssessmentType.Exam}
              showError={!hasAgreedToSubmissionDeclaration}
              onUpdateAgreement={(hasAgreed) => {
                if (hasAgreed) return dispatch(acceptSubmissionDeclaration());
                return dispatch(declineSubmissionDeclaration());
              }}
            />
          </AcademicIntegrityContainer>
          <Spacer spacing={45} />
          {format === TaskFormat.Classic && sheet.rawContent && (
            <>
              <InstructionLabel title="Task" />
              <SheetContent content={sheet.rawContent} />
            </>
          )}
          {format === TaskFormat.Multiformat && <Overview />}
          <Spacer spacing={48} />
          <ResourceList resources={sheet.resources} />
        </>
      )}
      <Spacer spacing={108} />
      <ReleaseDate>
        <Text kind="bodySm" color="shade1">
          <strong>** Instructions</strong> were last updated on{" "}
          <NoWrap>{formatDateFull(new Date(sheet.releaseTimestamp))}</NoWrap>
        </Text>
      </ReleaseDate>
      <Spacer spacing={100} />
    </>
  );
}

// A Skeleton loader representing the full Sheet components
const InstructionSkeleton = (props: { assessmentName: string }) => (
  <>
    <Spacer spacing={63} />
    <InstructionsTitle kind="displayTwo">
      {props.assessmentName}
    </InstructionsTitle>
    <Spacer spacing={45} />
    <ReqSkeleton />
    <Spacer spacing={45} />
    <TaskSkeleton />
  </>
);

const AcademicIntegrityContainer = styled.div`
  max-width: 800px;
  margin: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 34px;
  padding: 0px 45px;
  min-width: 320px;
`;

const InstructionsTitle = styled(Text)`
  max-width: 800px;
  margin: auto;
  padding-left: 45px;
  padding-right: 45px;
  padding-bottom: 18px;
  box-sizing: border-box;
  min-width: 320px;
`;

const ReleaseDate = styled.div`
  width: 100%;
  max-width: 800px;
  min-width: 360px;
  padding: 0 45px;
  box-sizing: border-box;
  margin: auto;
  text-align: center;
`;

const NoWrap = styled.span`
  white-space: nowrap;
`;

// A skeleton for the requirements section during load
const ReqSkeleton = () => (
  <Padded>
    <SectionSkeleton />
    <Spacer spacing={27} />
    <RequirementsSkeleton />
  </Padded>
);

// A skeleton for the instruction section during load
const TaskSkeleton = () => (
  <Padded>
    <SectionSkeleton />
    <Spacer spacing={27} />
    <ParagraphSkeleton />
    <Spacer spacing={180} />
  </Padded>
);

const Padded = styled.div`
  padding: 0 45px;
  box-sizing: border-box;
  max-width: 800px;
  min-width: 360px;
  width: 100%;
  margin: auto;
`;

// Empty State
const EmptyInstructions = () => (
  <EmptyContainer>
    <Padded>
      <Img src={lockIllustration} alt="" />
      <Spacer spacing={36} />
      <Text kind="bodyMd" textAlign="center">
        Instructions haven’t been released. Check-in later to find everything
        you need to complete this assignment.
      </Text>
    </Padded>
  </EmptyContainer>
);

const EmptyContainer = styled.div`
  min-height: 100%;
  width: 100%;
  padding-top: 20vh;
  padding-bottom: 108px;
  box-sizing: border-box;
  background: ${colors.wine100};
`;

const Img = styled.img`
  display: block;
  width: 100%;
  max-width: 450px;
  margin: auto;
`;
