import { useMemo } from "react";
import { styled } from "styled-components";

import {
  CadmusEditorContent,
  useCadmusEditor,
} from "@vericus/cadmus-editor-prosemirror";

// Props for SheetContent
interface Props {
  /**
   * Serialised editor contents from the Instruction Sheet.
   */
  content: string;
}

/**
 * React component rendering the Sheet contents as a readonly editor.
 */
export function SheetContent(props: Props) {
  const { content: rawContent } = props;

  // XXX If content failed to parse, how should we handle it?
  const editorContent = useMemo(() => {
    const { content } = JSON.parse(rawContent);
    return JSON.parse(content);
  }, [rawContent]);

  const editor = useCadmusEditor(
    {
      editorId: "instructions",
      editorA11yLabel: "",
      content: editorContent,
      editable: false,
    },
    [editorContent]
  );

  return <InstructionsEditor editor={editor} />;
}

const InstructionsEditor = styled(CadmusEditorContent)`
  &:after {
    display: none;
  }
`;
