import { Editor } from "@vericus/cadmus-editor-prosemirror";

import { makeVar, useReactiveVar } from "@apollo/client";

import {
  __GLOBAL_ASSESSMENT_ID,
  __GLOBAL_CLIENT_ID,
  __GLOBAL_STUDENT_ID,
  __GLOBAL_TENANT,
  __GLOBAL_WORK_ID,
} from "@/client/globals";
import { AppDispatch } from "@/data/store";
import { EditorId } from "@/data/types";
import { AnswerBlockFragment, QuestionType } from "@/generated/graphql";

import { Snapshot } from "../snapshot";
import { setActiveEditor } from "./active-editor";
import { newAnswerEditor, newClassicEditor, SheetProps } from "./answer-editor";

/**
 * Record of Answer Block ID to a stateful store managing answer contents.
 *
 * Currently we are only dealing with stateful Prosemirror editors and therefore
 * the store will contain answers that are of type `QuestionType.Extended`
 * and Classic Editors.
 */
export type AnswerStore = Record<string, Editor>;

/** Singleton and Reactive Answer Store variable. */
export const answerStore = makeVar<AnswerStore>({});

/**
 * Reactive variable to indicate if the answer store has been loaded.
 *
 * We set this to true after the store is loaded. This is used to prevent the UI
 * from rendering before the store is ready.
 */
export const answerStoreLoaded = makeVar(false);

/** Hook to read the current state of the answer store. */
export function useAnswerStoreLoaded() {
  return useReactiveVar(answerStoreLoaded);
}

/**
 * Hook to connect th the answer editor for a given answer block ID if it is
 * registered with the store.
 */
export function useAnswerEditor(answerBlockId: string): Editor | null {
  const store = useReactiveVar(answerStore);
  return store[answerBlockId] || null;
}

/** Read the latest answer Editor instance registered for `answerBlockId`. */
export function getAnswerEditor(answerBlockId: string): Editor | null {
  const store = answerStore();
  return store[answerBlockId] || null;
}

/**
 * Initialise the global Answer store with the latest snapshots fetched from the
 * server.
 *
 * @param answerBlocks - The list of answer blocks to initialise the store with.
 * @param snapshots - The latest snapshots deserialised.
 * @param dispatch - The AppDispatch to dispatch to Redux Store.
 * @param sheetProps - Properties of Sheet needed to initialise editors.
 */
export function loadAnswerStore(
  answerBlocks: AnswerBlockFragment[],
  snapshots: Record<string, Snapshot>,
  dispatch: AppDispatch,
  sheetProps: SheetProps
) {
  const store = answerStore();

  const answerBlocksStore = buildAnswerBlocksStore(
    store,
    answerBlocks,
    snapshots,
    dispatch,
    sheetProps
  );

  // Build classic editors for both MFA and Classic formats
  // (Notes and possibly References are needed in both)
  const classicEditorsStore = buildClassicEditorsStore(
    answerBlocksStore,
    snapshots,
    dispatch,
    sheetProps
  );

  if (!sheetProps.isMultiformat) {
    setActiveEditor("body", classicEditorsStore[EditorId.Body]);
  }

  answerStore({
    ...answerBlocksStore,
    ...classicEditorsStore,
  });
}

/**
 * Then function builds the editors for extended questions. If the editor
 * already exists in the store, it will not be re-initialised.
 */
function buildAnswerBlocksStore(
  store: AnswerStore,
  answerBlocks: AnswerBlockFragment[],
  snapshots: Record<string, Snapshot>,
  dispatch: AppDispatch,
  sheetProps: SheetProps
): Record<string, Editor> {
  return answerBlocks.reduce((store, answerBlock) => {
    if (store[answerBlock.id]) {
      return store;
    }
    if (answerBlock.question?.questionType === QuestionType.Extended) {
      const snapshot = snapshots[answerBlock.id];
      const editor = newAnswerEditor({
        answerBlockId: answerBlock.id,
        content: snapshot?.answerDoc ?? null,
        version: snapshot?.version ?? 0,
        dispatch,
        editorOpts: {
          enableHyperlink: !sheetProps.isLockDownExam,
          enableImage: !sheetProps.isLockDownExam,
        },
      });
      return { ...store, [answerBlock.id]: editor };
    }
    return store;
  }, store);
}

/**
 * Then function builds the editors for body, notes & references with the
 * content from the latest snapshots.
 *
 * If the editor already exists in the store, it will not be re-initialised ->
 * This happens when the sheet is updated and the work query runs again. In this
 * case, we don't want to re-initialise the editors while the student might be
 * working on them.
 */

function buildClassicEditorsStore(
  store: AnswerStore,
  snapshots: Record<string, Snapshot>,
  dispatch: AppDispatch,
  sheetProps: SheetProps
): Record<EditorId, Editor> {
  const bodyEditor =
    store[EditorId.Body] ??
    newClassicEditor(EditorId.Body, snapshots, dispatch, sheetProps);

  const notesEditor =
    store[EditorId.Notes] ??
    newClassicEditor(EditorId.Notes, snapshots, dispatch, sheetProps);

  const referencesEditor =
    store[EditorId.References] ??
    newClassicEditor(EditorId.References, snapshots, dispatch, sheetProps);

  return {
    [EditorId.Body]: bodyEditor,
    [EditorId.Notes]: notesEditor,
    [EditorId.References]: referencesEditor,
  };
}

/** Run a function on all editors and ignore the result. */
export const forAllEditors = (
  f: (editor: Editor, editorId: string) => void
) => {
  const store = answerStore();

  Object.entries(store).forEach(([answerBlockId, editor]) => {
    f(editor, answerBlockId);
  });
};
