import { Button, Text } from "@vericus/cadmus-ui";

import gradeIcon from "@/assets/icons/grade.svg";
import similarityIcon from "@/assets/icons/similarity.svg";
import { useHeraResultLaunch } from "@/features/submission";
import { ResultFragment } from "@/generated/graphql";

import * as styles from "./CadmusGraderView.css";
import { DataBlock } from "./DataBlock";

interface Props {
  /** Pantheon gradebook Result with integration data. */
  result: ResultFragment;
}

export const CadmusGraderView = (props: Props) => {
  const handleLaunch = useHeraResultLaunch(props.result, "cadmusgrader");

  const similarityScore =
    props.result.similarity && props.result.similarity.display;

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <DataBlock
          imgSrc={gradeIcon}
          imgAlt=""
          data={
            props.result.grade === null ? (
              "—"
            ) : (
              <>
                {props.result.grade.display}{" "}
                <Text kind="headingSix" as="span">
                  / {props.result.grade.max}
                </Text>
              </>
            )
          }
          info="grade"
        />
        <DataBlock
          imgSrc={similarityIcon}
          imgAlt=""
          data={similarityScore !== null ? `${similarityScore}%` : "—"}
          info="similarity score"
        />
      </div>
      <Button
        kind="secondary"
        onClick={handleLaunch}
        disabled={handleLaunch === undefined}
      >
        view grades and feedback
      </Button>
    </div>
  );
};
