import { formatMilliseconds } from "@/utils/datetime/milliseconds-duration";

/**
 * Describes time remaining for an interval being counted down.
 */
export interface CountdownMessage {
  /**
   * Human friendly format for the time remaining.
   *
   * e.g. "10 minutes", "1 minute 59 seconds"
   */
  message: string;
  /**
   * Actual number of milliseconds remaining.
   */
  milliseconds: number;
  /**
   * The interval has passed.
   */
  passed: boolean;
}

/**
 * Create a new message payload for the remaining `milliseconds` interval.
 */
export function newCountdownMessage(milliseconds: number): CountdownMessage {
  if (milliseconds > 0) {
    return {
      message: formatMilliseconds(milliseconds),
      milliseconds,
      passed: false,
    };
  }

  return {
    message: "0 seconds",
    milliseconds: 0,
    passed: true,
  };
}

const MILLISECONDS_MINUTE = 60 * 1000;
const MILLISECONDS_HOUR = 60 * MILLISECONDS_MINUTE;

/**
 * Get when the next tick should happen to count down the `milliseconds`.
 */
export function nextTickTimeout(milliseconds: number): number {
  // > 12 hours
  if (milliseconds > 12 * MILLISECONDS_HOUR) {
    // Align to the hour by ticking over the extra 1-59 minutes.
    const spilloverMs = milliseconds % MILLISECONDS_HOUR;
    if (spilloverMs > 0) {
      return spilloverMs;
    }
    return MILLISECONDS_HOUR;
  }

  //  > 2 minutes
  if (milliseconds > 2 * MILLISECONDS_MINUTE) {
    // Align to the minute by ticking over the extra 0-59s.
    const spilloverMs = milliseconds % MILLISECONDS_MINUTE;
    if (spilloverMs > 0) {
      return spilloverMs;
    }
    return 60_000;
  }

  if (milliseconds >= 1000) {
    // Align to the minute by ticking over the extra ms.
    const spilloverMs = milliseconds % 1000;
    if (spilloverMs > 0) {
      return spilloverMs;
    }
    return 1_000;
  }

  return 1_000;
}
