import { createClient } from "@jitsu/react";
import { JitsuClient } from "@jitsu/sdk-js";

import * as config from "@/config";

// initialize Jitsu client
const jitsuClient = createClient({
  tracking_host: `${config.API_ENDPOINT}/ebisu`,
  key: "aphrodite",
  ip_policy: "strict",
  cookie_policy: "strict",
  randomize_url: true,
});

export default jitsuClient;

/**
 * Jitsu client which does not emit anything. Useful for feature flagging and
 * storybooks/tests.
 */
/* eslint-disable @typescript-eslint/no-empty-function,@typescript-eslint/no-explicit-any */
export const EmptyJitsuClient: JitsuClient = {
  track: async () => {},
  id: async (_userData: any) => {},
  init: (_opts: any) => {},
  rawTrack: async (_payload) => {},
  _send3p: async (_typeName, _3pPayload, _type) => {},
  interceptAnalytics: (_analytics) => {},
  set: (
    _properties: Record<string, any>,
    _opts?: { eventType?: string; persist?: boolean }
  ) => {},
  unset: (
    _propertyName: string,
    _opts: { eventType?: string; persist?: boolean }
  ) => {},
};
