import { useInstitution } from "@/graphql/hooks";

import {
  AcademicIntegrityAgreement,
  AcademicIntegrityAgreementProps,
} from "./AcademicIntegrityAgreement";

/**
 * Provide the AI Agreement with custom institution declaration if available in
 * the graphql cache.
 */
export function InstitutionAIAgreement(props: AcademicIntegrityAgreementProps) {
  const { data } = useInstitution();
  const customDeclarationText = data.customDeclarationText ?? undefined;

  return (
    <AcademicIntegrityAgreement
      customDeclarationText={customDeclarationText}
      {...props}
    />
  );
}
