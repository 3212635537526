// Simple exponential backoff with jitter delay calculation for an attempt.
// THANK YOU CHAT GPT
export function calculateDelayWithExponentialBackoffAndJitter(
  attemptNumber: number,
  baseDelay = 300,
  maxDelay = 5_000,
  factor = 1.5
) {
  const delay = baseDelay * Math.pow(factor, attemptNumber);
  const jitter = (Math.random() * delay) / 2;
  return Math.min(delay + jitter, maxDelay) + Math.random() * 250;
}
