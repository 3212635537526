import { styled } from "styled-components";

import { Text } from "@vericus/cadmus-ui";

import { RatingScale } from "./RatingScale";

export interface ExamRatingProps {
  onUpdateScore: (score: number) => void;
}

export const ExamRating = ({ onUpdateScore }: ExamRatingProps) => {
  return (
    <Container>
      <Text kind="bodyMd" bold color="grey600">
        How was your experience using Cadmus in this exam?
      </Text>
      <div>
        <RatingScale
          withBackgroundBoxes
          scaleLeftMargin={12}
          onUpdateScore={onUpdateScore}
        />
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
`;
