import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" {...props}>
    <circle cx="8.99424" cy="11.8043" r="1.4" fill="white" />
    <rect
      x="12.9919"
      y="6.0127"
      width="0.5"
      height="7.26722"
      rx="0.25"
      transform="rotate(37.1664 12.9919 6.0127)"
      fill="white"
    />
    <rect
      x="4.50952"
      y="9.98438"
      width="1.09526"
      height="5.0608"
      rx="0.54763"
      transform="rotate(-60 4.50952 9.98438)"
      fill="white"
    />
    <rect
      x="14.2371"
      y="8.29297"
      width="1.09602"
      height="6.54491"
      rx="0.548012"
      transform="rotate(60 14.2371 8.29297)"
      fill="white"
    />
    <path
      d="M1.9529 0.611478L1.45534 0.971682C0.552109 1.62595 0.347698 2.90081 1.00161 3.80403C1.29532 4.20898 1.68756 4.51853 2.17608 4.16511L4.76159 2.29359C5.25691 1.94194 5.08228 1.47606 4.78492 1.06519C4.13137 0.161959 2.85644 -0.0424324 1.9529 0.611478Z"
      fill="white"
    />
    <path
      d="M16.9328 4.02474C17.7448 2.94306 17.7199 2.0364 16.6814 0.997551L16.1826 0.623059C15.2843 -0.0508542 14.0226 0.137136 13.3434 1.02748C13.0499 1.41835 12.8562 1.88858 13.3455 2.25557L15.8978 4.1722C16.2236 4.41646 16.6884 4.35013 16.9328 4.02474Z"
      fill="white"
    />
    <circle cx="9" cy="10.9443" r="7.85488" stroke="white" strokeWidth="1.3" />
  </svg>
);

export default SvgComponent;
