import { getVersion } from "@vericus/cadmus-editor-prosemirror";

import { ApolloError, FetchResult } from "@apollo/client";
import { createAsyncThunk, GetThunkAPI } from "@reduxjs/toolkit";

import client from "@/client/apollo";
import { __GLOBAL_SESSION_ID } from "@/client/globals";
import { RootState } from "@/data/store";
import { EditorId } from "@/data/types";
import { selectAssessmentFormat } from "@/features/assignment";
import {
  SaveBlockSnapshotMutation,
  SaveMutation,
  SubmissionType,
  SubmitV2Document,
  SubmitV2Mutation,
  SubmitV2MutationVariables,
  TaskFormat,
} from "@/generated/graphql";
import {
  readAnswerBlocks,
  readLatestBlockSnapshot,
  readLatestSave,
} from "@/graphql/selectors";
import { getAnswerEditor, getTotalWordCount } from "@/stores/answer-store";
import {
  serialiseSnapshot,
  serialiseSnapshotsAsSave,
  Snapshot,
} from "@/stores/snapshot";

import { onCloudSave, onSnapshot, onSubmit } from "./api";
import {
  selectAnswerSnapshot,
  selectIsSubmitting,
  selectSavingMechanism,
  selectSubmitDate,
  selectWorkId,
} from "./selectors";
import {
  PendingSnapshot,
  SaveError,
  SavingMechanism,
  SubmitError,
} from "./types";

export interface SaveBlockSnapshotPayload {
  /** The answer block id to save the snapshot to. */
  answerBlockId: string;
  /** Snapshot state */
  snapshot: Snapshot;
  /** Whether the action is triggered via an editor auto save. */
  isAutoSave: boolean;
}

/**
 * Perform GraphQL mutation to save a snapshot of the current state of the
 * document. It will send the following blocks:
 * - body
 * - notes
 * - references
 */
export const saveBlockSnapshot = createAsyncThunk<
  FetchResult<SaveBlockSnapshotMutation>,
  PendingSnapshot,
  { state: RootState; rejectValue: SaveError }
>("authority/snapshotBlock", async (payload, thunkAPI) => {
  const { snapshot, answerBlockId } = payload;
  const state = thunkAPI.getState();
  const assessmentId = state.assignment.assessmentId;
  const workId = state.authority.workId;

  if (!workId || !assessmentId) {
    throw new Error("Student Work not loaded into Redux");
  }

  const currentBlockSnapshot = readLatestBlockSnapshot(answerBlockId);
  const previousSnapshotId = currentBlockSnapshot?.id ?? null;
  const content = JSON.stringify(serialiseSnapshot(snapshot));

  try {
    const result = await onSnapshot(
      workId,
      answerBlockId,
      content,
      snapshot.version,
      previousSnapshotId
    );
    return result;
  } catch (error) {
    if (error instanceof ApolloError && isAlreadyReportedError(error)) {
      return thunkAPI.rejectWithValue({
        kind: "already_reported",
        title: "Save error",
        detail: "The version has been already saved.",
      });
    }
    throw error;
  }
});

interface SaveFullPayload {
  /**
   * The classic editor snapshot to save.
   *
   * Should pass any of the editors that had changes and the new content will
   * override the latest snapshots
   */
  pendingSnapshot?: PendingSnapshot;
}

/**
 * Async Cloud Saving request action using the `Save` graphql mutation.
 */
export const saveFull = createAsyncThunk<
  FetchResult<SaveMutation>,
  SaveFullPayload | undefined,
  { state: RootState; rejectValue: SaveError }
>("authority/saveWork", async (payload, thunkAPI) => {
  // Read the latest save information from the graphql cache
  const latestSave = readLatestSave();

  const state = thunkAPI.getState();
  const workId = state.authority.workId;
  const assessmentId = state.assignment.assessmentId;

  if (!workId || !assessmentId) {
    throw new Error("Student Work not loaded into Redux");
  }

  const metadata = {
    workId,
    assessmentId,
    sessionId: __GLOBAL_SESSION_ID.current,
    prevSaveId: latestSave?.serverId ?? null,
    prevVersionId: latestSave?.version ?? null,
  };

  const doc = serialiseSnapshotsAsSave(
    {
      body: prepareClassicSnapshot(
        EditorId.Body,
        state,
        payload?.pendingSnapshot
      ),
      references: prepareClassicSnapshot(
        EditorId.References,
        state,
        payload?.pendingSnapshot
      ),
      notes: prepareClassicSnapshot(
        EditorId.Notes,
        state,
        payload?.pendingSnapshot
      ),
    },
    metadata
  );

  const content = JSON.stringify(doc);
  return onCloudSave(workId, content, metadata);
});

// Pick the editors snapshot from:
//
//   1. The action payload, or
//   2. The authority slice state holding snapshots, or
//   3. The live editor instance in the answer store, or
//   4. Empty version 0 snapshot
//
function prepareClassicSnapshot(
  editorId: EditorId,
  state: RootState,
  payload?: PendingSnapshot
): Snapshot {
  if (payload?.answerBlockId === editorId) {
    return payload.snapshot;
  }
  const maybeSnapshot = selectAnswerSnapshot(state, editorId);
  if (maybeSnapshot) {
    return maybeSnapshot;
  }
  const editor = getAnswerEditor(editorId);
  const answerDoc = editor?.getJSON();
  const version = editor ? getVersion(editor.state) : 0;
  return {
    version,
    answerDoc,
  };
}

export interface SubmitPayload {
  // Lock the submission date. @default NOW.
  submissionDate?: string;
  // Select a submission type. @default Final
  submissionType?: SubmissionType;
  // Trigger was an auto-submission. @default false.
  autoSubmission?: boolean;
  // Submission attempt number. @default 1.
  attempt?: number;
}

/**
 * Submit the work.
 */
export const submit = createAsyncThunk<
  unknown,
  SubmitPayload,
  { state: RootState; rejectValue: SubmitError }
>(
  "authority/submit",
  async (payload, thunkAPI) => {
    // Depending on the saving mechanism, choose the appropriate submit action
    const state = thunkAPI.getState();
    const savingMechanism = selectSavingMechanism(state);
    const format = selectAssessmentFormat(state);

    const totalWC = getTotalWordCount();
    if (format === TaskFormat.Classic && totalWC < 20) {
      return thunkAPI.rejectWithValue({
        kind: "validation",
        title: "Incomplete work",
        detail: "You must have 20 words or more to submit.",
      });
    }

    if (savingMechanism === SavingMechanism.BLOCK) {
      return submitBlockSnapshots(payload, state, thunkAPI);
    }

    /*
    // It's an older way to check for pending saves before submitting,
    // now all the saving is done by snapshotQueueListener and we need to find a way synchronously flush the queue before submission
    const pendingSave = hasPendingSave();
    const saveError = selectSaveError(thunkAPI.getState());
    if (pendingSave || saveError) {
      await thunkAPI.dispatch(saveFull({ isAutoSave: false }));
    }
    */

    // Read the latest save information from the graphql cache
    const workId = selectWorkId(state);
    const latestSave = readLatestSave();
    const saveId = latestSave?.serverId;

    if (!saveId || !workId) {
      return thunkAPI.rejectWithValue({
        kind: "validation",
        title: "Incomplete work",
      });
    }

    const submittedAt = payload?.submissionDate || selectSubmitDate(state);
    const submissionType = payload?.submissionType
      ? payload.submissionType
      : SubmissionType.Final;

    // Add a jitter for auto submissions
    if (payload?.autoSubmission) {
      await new Promise((r) => setTimeout(r, Math.random() * 3000));
    }

    try {
      return await onSubmit(workId, saveId, submissionType, submittedAt);
    } catch (error) {
      if (error instanceof ApolloError) {
        if (isAlreadySubmitted(error)) {
          return thunkAPI.rejectWithValue({
            kind: "validation",
            title: "Submission error",
            detail: "Submission with newer save exists",
          });
        } else if (isValidationError(error)) {
          return thunkAPI.rejectWithValue({
            kind: "validation",
            title: "Submission error",
            detail: "We couldn't process this request. Please, try again later",
          });
        }
      }
      throw error;
    }
  },
  {
    condition: (_arg, { getState }) => {
      const state = getState();

      // Don't fire another submission attempt, if another is in progress
      if (selectIsSubmitting(state)) {
        return false;
      }

      if (!state.authority.workId) {
        return false;
      }

      return true;
    },
  }
);

/**
 * Async submit function using `SubmitV2` graphql mutation for block snapshots
 */
export async function submitBlockSnapshots(
  payload: SubmitPayload,
  state: RootState,
  thunkAPI: GetThunkAPI<{
    state: RootState;
    rejectValue: SubmitError;
  }>
) {
  const blocks = readAnswerBlocks();
  const blockSnapshotIds = blocks.flatMap((blk) => {
    const snapshot = readLatestBlockSnapshot(blk.id);
    return snapshot ? [snapshot.id] : [];
  });

  if (blockSnapshotIds.length === 0) {
    return thunkAPI.rejectWithValue({
      kind: "validation",
      title: "Incomplete work",
      detail: "There is not enough words to submit.",
    });
  }

  // Condition in submit ensures workId is not null
  const workId = selectWorkId(state)!;
  const submittedAt = payload?.submissionDate || selectSubmitDate(state);

  try {
    const result = await client.mutate<
      SubmitV2Mutation,
      SubmitV2MutationVariables
    >({
      mutation: SubmitV2Document,
      variables: {
        workId,
        blockSnapshotIds,
        type: payload.submissionType ?? SubmissionType.Final,
        submittedAt,
      },
    });
    return result;
  } catch (error) {
    if (error instanceof ApolloError && isValidationError(error)) {
      return thunkAPI.rejectWithValue({
        kind: "validation",
        title: "Submission error",
        detail: "We couldn't process this request. Please, try again later",
      });
    }
    throw error;
  }
}

function isValidationError(error: ApolloError) {
  return (
    error.graphQLErrors.length > 0 &&
    error.graphQLErrors[0] &&
    // in case of validation error pantheon returns formatted error with code field
    "code" in error.graphQLErrors[0] &&
    error.graphQLErrors[0].code === "VALIDATION_ERROR"
  );
}

function isAlreadyReportedError(error: ApolloError) {
  return (
    error.graphQLErrors.length > 0 &&
    error.graphQLErrors[0] &&
    "reason" in error.graphQLErrors[0] &&
    error.graphQLErrors[0].reason === "already_reported"
  );
}

function isAlreadySubmitted(error: ApolloError) {
  return (
    error.graphQLErrors.length > 0 &&
    error.graphQLErrors[0] &&
    "reason" in error.graphQLErrors[0] &&
    error.graphQLErrors[0].reason === "newer_exist"
  );
}
