import { styled } from "styled-components";

import { Button, LinkButton, Modal, Spacer, Text } from "@vericus/cadmus-ui";

import { InstitutionAIAgreement } from "@/components/acadmic-integrity-agreement";

export interface TimedSubmitModalProps {
  /**
   * There is a prior final submission.
   */
  hasFinal: boolean;
  /**
   * Can make a late submission in the current state.
   */
  canSubmitLateExam: boolean;
  /**
   * Number of minutes of late submission allowance. A `null` value means
   * un-bounded limit if `canSubmitLateExam` is true.
   */
  extraSubmissionTime: number | null;
  /**
   * Is the academic integrity agreement checked?.
   */
  isAgreementChecked: boolean;
  /**
   * Callback to update the integrity agreement state.
   */
  onUpdateAgreement: (checked: boolean) => void;
  /**
   * Callback when submit now action is invoked.
   */
  onSubmitNowClick: VoidFunction;
  /**
   * Callback when submit later action is invoked.
   */
  onSubmitLateClick: VoidFunction;
  open: boolean;
  onExitComplete?: VoidFunction;
}

/**
 * Modal component which displays submission choices for timed assignments and
 * exams.
 */
export function TimedSubmitModal(props: TimedSubmitModalProps) {
  const {
    hasFinal,
    canSubmitLateExam,
    extraSubmissionTime,
    isAgreementChecked,
    onUpdateAgreement,
    onSubmitLateClick,
    onSubmitNowClick,
    open,
    onExitComplete,
  } = props;

  let message = null;
  if (canSubmitLateExam && extraSubmissionTime) {
    message = (
      <>
        Submit what you have now, or continue working. <br />
        You can submit up to {extraSubmissionTime} minutes late, but you may
        incur a penalty. <br />
        After {extraSubmissionTime} minutes you will not be able to submit.
      </>
    );
  } else if (canSubmitLateExam) {
    message = (
      <>
        Submit what you have now, or continue working. <br />
        You can submit late, but you may incur a penalty.
      </>
    );
  } else if (!canSubmitLateExam && hasFinal) {
    message = (
      <>
        {"You’ve"} already made a submission for this exam. <br />
        Do you want to resubmit with your latest changes?
      </>
    );
  }

  return (
    <Modal.Root open={open}>
      <StyledModalContent
        withCloseButton={false}
        onExitComplete={onExitComplete}
      >
        <Modal.Title asChild>
          <Text kind="headingOne">{"Time's"} up!</Text>
        </Modal.Title>
        <Modal.Description asChild>
          <Text kind="bodyMd">{message}</Text>
        </Modal.Description>
        <InstitutionAIAgreement
          withHeader
          withShortenedPledge
          isChecked={isAgreementChecked}
          onUpdateAgreement={onUpdateAgreement}
          showError={!isAgreementChecked}
        />
        <div>
          <ActionButton
            fullWidth
            kind="primary"
            disabled={!isAgreementChecked}
            size="lg"
            iconName="Send"
            iconPosition="right"
            onClick={onSubmitNowClick}
            title={"Submit exam now"}
            aria-label={"Submit exam now"}
          >
            Submit now
          </ActionButton>
          <Spacer spacing={16} />
          {canSubmitLateExam && (
            <LinkButton
              onClick={onSubmitLateClick}
              aria-label="Close modal and keep writing"
            >
              Continue working and submit late
            </LinkButton>
          )}
          {!canSubmitLateExam && hasFinal && (
            <ActionButton
              size="lg"
              title="Close modal and keep previous submission"
              aria-label="Close modal and keep previous submission"
              onClick={onSubmitLateClick}
            >
              Discard and keep my previous submission
            </ActionButton>
          )}
        </div>
      </StyledModalContent>
    </Modal.Root>
  );
}

const StyledModalContent = styled(Modal.Content)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  text-align: center;
  padding: 88px 48px;
  overflow-y: auto;
`;

const ActionButton = styled(Button)`
  width: 336px;
`;
