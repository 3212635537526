import { styled, keyframes } from "styled-components";

import { Text } from "@vericus/cadmus-ui";

import { SubmissionType } from "generated/graphql";

import alarmDoodle from "@/assets/icons/alarm-doodle.svg";

import {
  NoAllowedSubmissions,
  SubmissionOption,
} from "./createAllowedSubmissions";

// ------------------------- No Submissions -------------------------

export const noSubmission: NoAllowedSubmissions = {
  option: "none",
  title: "Sorry. No submissions are allowed right now.",
  subtext: "No submissions allowed",
};

export const noLateSubmission: NoAllowedSubmissions = {
  option: "none",
  title: "Sorry. It's past due",
  subtext:
    "The final due date has passed and submissions are no longer allowed.",
};

export const noOvertimeSubmission: NoAllowedSubmissions = {
  option: "none",
  title: "Sorry. Time's up",
  subtext: "The time limit has passed and submissions are no longer allowed.",
};

export const noLateAndOvertimeSubmission: NoAllowedSubmissions = {
  option: "none",
  title: "Sorry. It's past due",
  subtext:
    "The final due date and time limit have passed and submissions are no longer allowed.",
};

// ------------------------- Draft Submissions -------------------------

export const submitDraftSubmission: SubmissionOption = {
  text: "Submit as Draft",
  subtext:
    "Submit your work as a draft. You can resubmit a draft as many times as you like before the draft due date, but you won't be able to resubmit once this date has passed.",
  type: SubmissionType.Draft,
};

export const resubmitDraftSubmission: SubmissionOption = {
  text: "Resubmit Draft",
  subtext:
    "You already submitted a draft. Resubmitting will replace your previous draft submission. You can resubmit a draft as many times as you like before the draft due date, but you won't be able to resubmit once this date has passed.",
  type: SubmissionType.Draft,
};

export const lateDraftSubmission: SubmissionOption = {
  text: "Submit late Draft",
  subtext:
    "You didn't submit a draft before the draft due date. If you submit now, your draft will be marked late. You won't be able to resubmit a draft after this.",
  type: SubmissionType.Draft,
};

// ------------------------- Final Submissions -------------------------

export const submitFinalSubmission: SubmissionOption = {
  text: "Submit as Final",
  subtext:
    "Submit your work as a final. You may resubmit a final as many times as you like before the final due date.",
  type: SubmissionType.Final,
};

export const resubmitFinalSubmission: SubmissionOption = {
  text: "Resubmit Final",
  subtext:
    "Resubmitting will replace your current submission. You may resubmit a final as many times as you like before the final due date.",
  type: SubmissionType.Final,
};

export const lateFinalSubmission: SubmissionOption = {
  text: "Submit late Final",
  subtext: `Since the due date has passed, your submission will be marked as late and penalties may apply.`,
  type: SubmissionType.Final,
};

export const overtimeSubmission: SubmissionOption = {
  text: "Submit overtime Final",
  subtext: `You didn't submit a final within the time limit. If you submit now, your final will be marked overtime and penalties may apply. You won't be able to resubmit after this.`,
  type: SubmissionType.Final,
};

export const lateResubmission: SubmissionOption = {
  text: "Resubmit final",
  subtext:
    "Resubmitting will replace your current submission and your work will be marked as late, unless you receive an extension.",
  type: SubmissionType.Final,
};

export const lateAndOvertimeSubmission: SubmissionOption = {
  text: "Submit late + overtime Final",
  subtext: `You didn’t submit a final before the final due date or within the time limit. If you submit now, your final will be marked late and overtime. Penalties may apply. You won't be able to resubmit after this.`,
  type: SubmissionType.Final,
};

/**
 * On time limit
 *
 * The subtext to display when auto-redirecting to the PreviewPage when the time
 * limt expires
 */
const OnTimeLimit = () => (
  <>
    <Title>
      <Img src={alarmDoodle} alt="A doodle illustration of an alarm clock" />
      <Text kind="headingThree">{"Time's"} up</Text>
    </Title>
    <Text kind="bodyMd">
      You can either submit what you have, or continue working. If you continue
      working, your work will be marked as overtime and penalties may apply.
    </Text>
  </>
);

const alarm = keyframes`
  3%, 25% {
    transform: rotate(-1deg);
  }
  
  5%, 20% {
    transform: rotate(2deg);
  }

  7%, 12%, 16% {
    transform: rotate(-4deg);
  }

  10%, 15% {
    transform: rotate(4deg);
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Img = styled.img`
  display: block;
  height: 36px;
  margin-right: 9px;
  animation: ${alarm} 4s 1s ease infinite;
  transform-origin: bottom center;
`;

export const onTimeSubmission: SubmissionOption = {
  text: "Submit as Final",
  subtext: <OnTimeLimit />,
  type: SubmissionType.Final,
};
