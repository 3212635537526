import { createAction } from "@reduxjs/toolkit";
import { isAfter } from "date-fns";

import {
  RequirementsFragment,
  WorkSettingsFragment,
} from "@/generated/graphql";

/** Action payload for the updateSheet action. */
export interface HydrateSheetPayload {
  /** ID of the assessment. */
  assessmentId: string;
  /** Date when the work was manually started. */
  workStartDate: string | null;
  /** Settings applied directly for the student's work. */
  settings: WorkSettingsFragment | null;
  /** Settings applied on the overall assesssment. */
  requirements: RequirementsFragment | null;
}

/**
 * Create the `assignment/updateSheet` action.
 *
 * This action is used to synchronise the latest settings and requirements for
 * the Student's Work and Assessment with current state.
 */
export const updateSheet = createAction<HydrateSheetPayload>(
  "assignment/updateSheet"
);

/**
 * Select a assignment time limit set for the Work.
 *
 * This time limit can come from the Sheet or the Work's Settings (extension).
 * If both are present, the greater is taken.
 *
 * @param payload action payload
 * @return selected time limit and whether it was an extension or sheet's
 *   version.
 */
export function getTimeLimit(
  payload: HydrateSheetPayload
): [number | null, boolean] {
  const sheetTimeLimit = payload.requirements?.timeLimit ?? null;
  const workTimeLimit = payload.settings?.timeLimit ?? null;
  return selectTimeOrExtension(sheetTimeLimit, workTimeLimit);
}

/**
 * Select a final due date for the Work.
 *
 * This date can come from the Sheet or the Work's Settings (extension),
 * whichever is later.
 *
 * @param payload action payload
 * @return selected due date and whether it was an extension or sheet's
 *   version.
 */
export function getFinalDate(
  payload: HydrateSheetPayload
): [string | null, boolean] {
  const sheetFinalDate = payload.requirements?.dueDate ?? null;
  const workFinalDate = payload.settings?.dueDate ?? null;
  return selectDateOrExtension(sheetFinalDate, workFinalDate);
}

/**
 * Select an exam start date for a Work.
 *
 * This date can come from the Sheet or the Work's Settings (if Special
 * Consideration is provided to the student). The Special Consideration date
 * takes precedence.
 *
 * @param payload action payload
 * @return selected date and whether it's a sheet date (false) or a special
 *   consideration (true).
 */
export function getExamStartDate(
  payload: HydrateSheetPayload
): [string | null, boolean] {
  const sheetStartDate = payload.requirements?.examStartDate ?? null;
  const specialStartDate = payload.settings?.examStartDate ?? null;
  return [specialStartDate || sheetStartDate, specialStartDate !== null];
}

/**
 * Select an exam end date for a Work.
 *
 * This date can come from the Sheet or the Work's Settings (if Special
 * Consideration is provided to the student). The Special Consideration date
 * takes precedence.
 *
 * @param payload action payload
 * @return selected date and whether it's a sheet date (false) or a special
 *   consideration (true).
 */
export function getExamEndDate(
  payload: HydrateSheetPayload
): [string | null, boolean] {
  const sheetEndDate = payload.requirements?.examEndDate ?? null;
  const specialEndDate = payload.settings?.examEndDate ?? null;
  return [specialEndDate || sheetEndDate, specialEndDate !== null];
}

/**
 * Select a reading time set for a Work.
 *
 * This value can come from the Sheet or the Work's Settings (if Special
 * Consideration is provided to the student). The Special Consideration value
 * takes precedence.
 *
 * @param payload action payload
 * @return selected time value and whether it's from the sheet (false) or a
 *   special consideration (true).
 */
export function getExamReadingTime(
  payload: HydrateSheetPayload
): [number | null, boolean] {
  const sheetReadingTime = payload.requirements?.examReadingTime ?? null;
  const specialReadingTime = payload.settings?.examReadingTime ?? null;
  return [specialReadingTime ?? sheetReadingTime, specialReadingTime !== null];
}

/**
 * Select a writing time set for a Work.
 *
 * This value can come from the Sheet or the Work's Settings (if Special
 * Consideration is provided to the student). The Special Consideration value
 * takes precedence.
 *
 * @param payload action payload
 * @return selected time value and whether it's from the sheet (false) or a
 *   special consideration (true).
 */
export function getExamWritingTime(
  payload: HydrateSheetPayload
): [number | null, boolean] {
  const sheetWritingTime = payload.requirements?.examWritingTime ?? null;
  const specialWritingTime = payload.settings?.examWritingTime ?? null;
  return [specialWritingTime ?? sheetWritingTime, specialWritingTime !== null];
}

function selectDateOrExtension(
  sheetDateStr: string | null,
  workDateStr: string | null
): [string | null, boolean] {
  if (sheetDateStr !== null && workDateStr !== null) {
    const validExtension = isAfter(
      new Date(workDateStr),
      new Date(sheetDateStr)
    );
    const date = validExtension ? workDateStr : sheetDateStr;
    return [date, validExtension];
  }

  if (sheetDateStr !== null) {
    return [sheetDateStr, false];
  }

  return [null, false];
}

function selectTimeOrExtension(
  sheetMinutes: number | null,
  workMinutes: number | null
): [number | null, boolean] {
  const validExtension =
    sheetMinutes !== null
      ? workMinutes !== null && workMinutes > sheetMinutes
      : false;
  const time = validExtension ? workMinutes : sheetMinutes;
  return [time, validExtension];
}
