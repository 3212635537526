import { styled, css } from "styled-components";

import { ControlButton } from "@vericus/cadmus-ui";

// Positioning div for the Close button.
const CloseAction = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 18px;
  z-index: 100;
  pointer-events: all;

  ${(p) =>
    !p.isOpen &&
    css`
      transform: translateX(40px);
      background: white;
      box-shadow:
        0px 0px 1px rgba(71, 92, 133, 0.36),
        0px 1px 3px rgba(71, 92, 133, 0.15),
        1px 4px 7px -6px rgba(71, 92, 133, 0.06);
      border-radius: 4px;
    `}
`;

interface PanelControlProps {
  /** Callback for the control button click */
  onClick: () => void;
  /** Open state of the panel */
  isOpen: boolean;
  /** Name of the tab to show tooltip accordingly */
  tab: string;
}

/**
 * Displays the control open/close button on the right edge of resizable panel.
 */
export function PanelControl(props: PanelControlProps) {
  const { onClick, isOpen, tab } = props;
  return (
    <CloseAction isOpen={props.isOpen}>
      <ControlButton
        aria-label={!isOpen ? `Open ${tab}` : `Close ${tab}`}
        iconName={!isOpen ? `Sidebar` : `Close`}
        onClick={onClick}
      />
    </CloseAction>
  );
}
