import { styled } from "styled-components";

import { Divider, Text } from "@vericus/cadmus-ui";

import { ReviewableAnswer } from "../../hooks/use-reviewable-answers";
import { StatusTag } from "../status-tag";
import * as styles from "./review-list.css";

interface Props {
  answers: ReviewableAnswer[];
  onGoToAnswer: (answerBlockId: string) => void;
}

/**
 * List reviwable answers in a list navigation.
 */
export const ReviewList = ({ answers, onGoToAnswer }: Props) => (
  <ul className={styles.list}>
    {answers.map((answer, index, arr) => (
      <li key={answer.title} className={styles.item}>
        <div className={styles.itemContent}>
          <QuestionLink
            role="button"
            tabIndex={0}
            onClick={() => onGoToAnswer(answer.answerBlockId)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                onGoToAnswer(answer.answerBlockId);
              }
            }}
          >
            <Text kind="headingSix" color="lilac700" as="span">
              {answer.title}
            </Text>
          </QuestionLink>
          <StatusTag
            bookmarked={answer.bookmarked}
            skipped={answer.skipped}
            expanded
          />
        </div>
        {index !== arr.length - 1 && <Divider style={{ width: "80%" }} />}
      </li>
    ))}
  </ul>
);

const QuestionLink = styled.div`
  cursor: pointer;

  &:hover {
    opacity: 80%;
    text-decoration: underline;
  }
`;
