import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { addMinutes, isAfter } from "date-fns";
import { getCurrentDate } from "utils/datetime";

import {
  getExamEndDate,
  getExamReadingTime,
  getExamStartDate,
  getExamWritingTime,
  getFinalDate,
  getTimeLimit,
  updateSheet,
} from "@/features/assignment";
import { AssessmentType, ExamTiming } from "@/generated/graphql";

/**
 * A date information object.
 */
export interface TimelineDate {
  date: string | null;
  passed: boolean;
  extended: boolean;
}

export interface TimelineState {
  /**
   * Latest instruction sheet release date.
   */
  releaseDate: TimelineDate;
  /**
   * Date when the next scheduled Sheet will be available.
   */
  nextReleaseDate: TimelineDate;
  /**
   * Date when the exam is open to the students.
   *
   * For LIVE exams this is the date the students can see their sheet and start
   * the exam.
   *
   * For WINDOW exams this is the date after which a student can choose to see
   * the sheet and manually elect to start the exam.
   *
   * For assignments this date is not used.
   */
  examStartDate: TimelineDate;
  /**
   * Date when the student elects to start working.
   *
   * For LIVE exams this date is not used, and instead `examStartDate` is
   * implicitly used.
   *
   * For WINDOW exams this is the date when the student elects to /START/ their
   * work.
   *
   * For TIMED assignments this is the date when the student elects to /START/
   * their work.
   *
   * For assignments this date is not used.
   */
  workStartDate: TimelineDate;
  /**
   * Date when the student's allotted reading time ends in an EXAM.
   */
  readingDate: TimelineDate;
  /**
   * Date when the student's allotted writing time ends in EXAMS and timed ASSIGNMENTS.
   *
   * For LIVE exams this date is `examStartDate` + `readingTime` +
   * `writingTime`.
   *
   * For WINDOW exams this date is the earlier of `examEndDate` and
   * `workStartDate` + `readingTime` + `writingTime`.
   *
   * For TIMED assignments this date is `workStartDate` + `timeLimit`.
   *
   * For assignments this date is not used.
   */
  writingDate: TimelineDate;
  /**
   * Date when the exam ends.
   *
   * For LIVE exams this date is not used, instead the `writingDate` should be
   * used.
   *
   * For WINDOW exams this date is `examEndDate` from the sheet.
   *
   * For assignments this date is not used.
   */
  examEndDate: TimelineDate;
  /**
   * Date until when exam submissions will be accepted whether late or on time.
   *
   * This date is only meaningful for exams and when auto-submissions are turned
   * off AND when there is a late submission limit.
   *
   * If the assessment is not an exam, then the date is `null`.
   *
   * If the late submission limit is /unlimited/, then the date is `null`.
   */
  examLateSubmissionDate: TimelineDate;
  /**
   * Due date for draft submissions in assignments.
   */
  draftDate: TimelineDate;
  /**
   * Due date for final submissions in assignments.
   */
  finalDate: TimelineDate;
  /**
   * Date after which feedback is available to be viewed by the students.
   */
  feedbackDate: TimelineDate;
}

const mkDateState = () => ({ date: null, passed: false, extended: false });

const initialState: TimelineState = {
  releaseDate: mkDateState(),
  nextReleaseDate: mkDateState(),
  examStartDate: mkDateState(),
  workStartDate: mkDateState(),
  readingDate: mkDateState(),
  draftDate: mkDateState(),
  writingDate: mkDateState(),
  finalDate: mkDateState(),
  examEndDate: mkDateState(),
  feedbackDate: mkDateState(),
  examLateSubmissionDate: mkDateState(),
};

export const timelineSlice = createSlice({
  name: "timeline",
  initialState,
  reducers: {
    examStartDatePassed: (state) => {
      state.examStartDate.passed = true;
    },
    examEndDatePassed: (state) => {
      state.examEndDate.passed = true;
    },
    workStartDatePassed: (state, action: PayloadAction<string>) => {
      state.workStartDate.date = action.payload;
      state.workStartDate.passed = true;
    },
    readingDatePassed: (state) => {
      state.readingDate.passed = true;
    },
    writingDatePassed: (state) => {
      state.writingDate.passed = true;
    },
    examLateSubmissionDatePassed: (state) => {
      state.examLateSubmissionDate.passed = true;
    },
    draftDatePassed: (state) => {
      state.draftDate.passed = true;
    },
    finalDatePassed: (state) => {
      state.finalDate.passed = true;
    },
    feedbackDatePassed: (state) => {
      state.feedbackDate.passed = true;
    },
    nextReleaseDatePassed: (state) => {
      state.nextReleaseDate.passed = true;
    },
    /**
     * A future sheet release has been scheduled or cancelled.
     */
    futureSheetScheduled: (state, action: PayloadAction<string | null>) => {
      state.nextReleaseDate.passed = false;
      if (action.payload !== null) {
        state.nextReleaseDate.date = action.payload;
      } else {
        state.nextReleaseDate.date = null;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateSheet, (state, action) => {
      // Query data
      const sheet = action.payload.requirements;
      const now = getCurrentDate();

      // Predicates
      const isExam = sheet?.assessmentType === AssessmentType.Exam;
      const isAssn = sheet?.assessmentType === AssessmentType.Assignment;
      const isTimedAssn = isAssn && sheet?.timeLimit !== null;
      const isLiveExam = isExam && sheet?.examTiming === ExamTiming.Live;
      const isWindowExam = isExam && sheet?.examTiming === ExamTiming.Window;

      // Release Date
      if (sheet?.releaseTimestamp) {
        state.releaseDate.date = sheet.releaseTimestamp;
        state.releaseDate.passed = true;
      }

      // Next release date
      setTimelineDate(
        state.nextReleaseDate,
        sheet?.nextReleaseTimestamp ?? null,
        now
      );

      // Reset some state if assessment changed to non timed assessment or exam
      if (!isTimedAssn && !isExam && state.writingDate.date !== null) {
        state.writingDate = mkDateState();
      }

      // Exam Start Date
      if (isExam) {
        const [startDate, extended] = getExamStartDate(action.payload);
        setTimelineDate(state.examStartDate, startDate, now, extended);
      }

      // Exam End Date
      if (isWindowExam) {
        const [endDate, extended] = getExamEndDate(action.payload);
        setTimelineDate(state.examEndDate, endDate, now, extended);
      }

      // Timed Assignment and Windowed Exam Work Start Date
      if (isTimedAssn || isWindowExam) {
        setTimelineDate(state.workStartDate, action.payload.workStartDate, now);
      }

      // Exam Reading Date
      if (isExam) {
        const [readingTime, extended] = getExamReadingTime(action.payload);
        const minutes = readingTime ?? 0;

        const start = isLiveExam
          ? state.examStartDate.date
          : state.workStartDate.date;
        let readingDate = null;
        if (minutes > 0 && start) {
          readingDate = addMinutes(new Date(start), minutes);
        }
        setTimelineDate(state.readingDate, readingDate, now, extended);
      }

      // Exam Writing Date
      if (isExam) {
        const [readingTime, extendedReading] = getExamReadingTime(
          action.payload
        );
        const [writingTime, extendedWriting] = getExamWritingTime(
          action.payload
        );
        const totalTime = (readingTime ?? 0) + (writingTime ?? 0);

        // Live Exam writing date
        if (isLiveExam) {
          let writingDate = null;
          if (state.examStartDate.date) {
            writingDate = addMinutes(
              new Date(state.examStartDate.date),
              totalTime
            );
          }
          setTimelineDate(state.writingDate, writingDate, now, extendedWriting);
        }

        // Window Exam writing date
        if (isWindowExam && state.workStartDate.date) {
          let writingDate = null;
          if (state.workStartDate.date) {
            writingDate = addMinutes(
              new Date(state.workStartDate.date),
              totalTime
            );
          }

          // Check case of limited writing time
          // Use the `examEndDate` as the writing date if it is earlier.
          if (
            writingDate &&
            state.examEndDate.date &&
            isAfter(writingDate, new Date(state.examEndDate.date))
          ) {
            writingDate = new Date(state.examEndDate.date);
            // Adjust Reading Date too
            const readingDate = addMinutes(writingDate, -(writingTime ?? 0));
            setTimelineDate(
              state.readingDate,
              readingDate,
              now,
              extendedReading
            );
          }

          setTimelineDate(state.writingDate, writingDate, now, extendedWriting);
        }
      }

      // Timed Assignment Writing Date
      if (isTimedAssn && state.workStartDate.date) {
        const [timeLimit, extended] = getTimeLimit(action.payload);
        if (state.workStartDate.date && timeLimit !== null) {
          const writingDate = addMinutes(
            new Date(state.workStartDate.date),
            timeLimit
          );
          setTimelineDate(state.writingDate, writingDate, now, extended);
        }
      }

      // Exam late submission date
      if (isExam) {
        let lateSubmissionDate = null;
        if (
          sheet?.enableExamAutoSubmission === false &&
          sheet?.lateSubmissionTimeLimit !== null &&
          state.writingDate.date
        ) {
          lateSubmissionDate = addMinutes(
            new Date(state.writingDate.date),
            sheet.lateSubmissionTimeLimit
          );
        }
        setTimelineDate(state.examLateSubmissionDate, lateSubmissionDate, now);
      }

      // Draft Due Date
      if (isAssn) {
        setTimelineDate(state.draftDate, sheet?.draftDueDate ?? null, now);
      }

      // Final Date
      if (isAssn) {
        const [final, finalExtended] = getFinalDate(action.payload);
        state.finalDate.date = final;
        state.finalDate.extended = finalExtended;
        if (final) {
          state.finalDate.passed = isAfter(now, new Date(final));
        }
      }

      // Feedback Date
      if (isAssn) {
        setTimelineDate(state.feedbackDate, sheet?.returnDate ?? null, now);
      }
      if (isExam) {
        setTimelineDate(
          state.feedbackDate,
          sheet?.examFeedbackDate ?? null,
          now
        );
      }
    });
  },
});

function setTimelineDate(
  draft: Draft<TimelineDate>,
  value: string | Date | null,
  now: Date,
  extended = false
) {
  if (value) {
    const date = new Date(value);
    draft.date = date.toISOString();
    draft.passed = isAfter(now, date);
    draft.extended = extended;
  } else {
    draft.date = null;
    draft.passed = false;
  }
}

export const {
  draftDatePassed,
  examEndDatePassed,
  examLateSubmissionDatePassed,
  examStartDatePassed,
  feedbackDatePassed,
  finalDatePassed,
  futureSheetScheduled,
  nextReleaseDatePassed,
  readingDatePassed,
  workStartDatePassed,
  writingDatePassed,
} = timelineSlice.actions;

export const timelineReducer = timelineSlice.reducer;
