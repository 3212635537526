export enum JitsuEvent {
  QUESTION_BOOKMARKED = "Question Bookmarked",
  QUESTION_SKIPPED = "Question Skipped",
  EDITOR_PASTED = "Editor Pasted",
  EDITOR_HISTORY_CHANGED = "Editor History Changed",
  RESOURCE_VIEWED = "Resource Viewed",
  MANUAL_VIEWED = "Manual Viewed",
  QUESTION_OPENED = "Question Opened",
  ANSWER_WORD_LIMIT_EXCEEDED = "Answer Word Limit Exceeded",
  BROWSER_CONNECTION_RESTORED = "Browser Connection Restored",
  FEEDBACK_VIEWER_OPENED = "Feedback Viewer Opened",
  FEEDBACK_VIEWER_TIME_SPENT = "Feedback Viewer Time Spent",
}
