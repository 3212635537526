import type { AppStartListening } from "@/data/listenerMiddleware";
import {
  markExamAsOver,
  selectIsExam,
  selectIsWindowExam,
  updateSheet,
} from "@/features/assignment";
import { selectCanSubmitLateExam } from "@/features/authority";
import {
  selectExamEndDatePassed,
  selectExamStartDatePassed,
  selectTimedWorkNotStarted,
  selectWritingDatePassed,
} from "@/features/timeline";

/**
 * Start exam related listeners.
 */
export function startExamListeners(startListening: AppStartListening) {
  startExamOverCheckListener(startListening);
}

/**
 * Check if the exam should be considered as over for the current state. This
 * check is only fired once at load when the `assignment/updateSheet` action
 * fires.
 */
export function startExamOverCheckListener(startListening: AppStartListening) {
  let firedOnce = false;
  startListening({
    actionCreator: updateSheet,
    effect: (_action, listenerApi) => {
      if (firedOnce) return;
      firedOnce = true;

      const state = listenerApi.getState();

      // Generally for all exams if the writing time is over and no more late
      // submissions can be made, the exam is considered over
      if (
        selectIsExam(state) &&
        selectWritingDatePassed(state) &&
        !selectCanSubmitLateExam(state)
      ) {
        listenerApi.dispatch(markExamAsOver());
      }

      // For window exams if the start and end window has passed, and the
      // student has not even started, the exam is over
      if (
        selectIsWindowExam(state) &&
        selectExamStartDatePassed(state) &&
        selectExamEndDatePassed(state) &&
        selectTimedWorkNotStarted(state)
      ) {
        listenerApi.dispatch(markExamAsOver());
      }
    },
  });
}
