import { lazy } from "react";

import { Route, Routes } from "react-router-dom";

import { NoMatchError, UnauthorizedError } from "@/components/errors";

import { Logout } from "./ui/shared/Logout";

const AppWithData = lazy(() => import("./ui/app/AppWithData"));
const FeedbackPage = lazy(
  () => import("./features/feedback/pages/feedback-viewer-page")
);

export const routes = (
  <Routes>
    <Route
      path="/"
      element={<NoMatchError />}
      errorElement={<NoMatchError />}
    />
    <Route
      path="/:tenant/:assessmentId/work/:workId/editing"
      element={<AppWithData />}
    />

    <Route
      path="/:tenant/:assessmentId/work/:workId/feedback"
      element={<FeedbackPage />}
    />
    <Route path="/logout" element={<Logout />} />
    <Route path="/goodbye" element={<UnauthorizedError />} />
  </Routes>
);
