import { useState } from "react";
import { styled } from "styled-components";

import { FileCard, getFileType, LinkCard } from "@vericus/cadmus-ui";

import { ResourceFragment } from "generated/graphql";

import {
  formatFileSize,
  getExtension,
  isLink,
  removeExtension,
  useOpenResource,
} from "./resource-utils";

interface Props {
  resource: ResourceFragment;
  children?: JSX.Element | false;
}

export const ResourceListItem = ({ children, resource }: Props) => {
  const open = useOpenResource(resource);
  const [focus, setFocus] = useState(false);
  const component = isLink(resource) ? (
    <LinkCard
      viewOnly
      linkTitle={resource.name ?? ""}
      onOpenInBrowser={open}
      focus={focus}
      onFocusChange={(focus) => setFocus(focus)}
    />
  ) : (
    <FileCard
      viewOnly
      fileInfo={{
        name: removeExtension(resource.name ?? ""),
        type: getFileType(resource.name ?? ""),
        size: resource.fileSize ? formatFileSize(resource.fileSize) : "",
        extension: getExtension(resource.name ?? ""),
      }}
      onOpenInBrowser={open}
      focus={focus}
      onFocusChange={(focus) => setFocus(focus)}
    />
  );
  return (
    <Container
      tabIndex={0}
      onFocus={() => setFocus(true)}
      onKeyDown={(e) => {
        if (e.shiftKey && e.key === "Tab" && e.currentTarget === e.target)
          setFocus(false);
      }}
      aria-label="Attached resource"
    >
      {component}
      {children}
    </Container>
  );
};

const Container = styled.div`
  box-shadow:
    0px 0px 1px rgba(71, 92, 133, 0.36),
    0px 1px 3px rgba(71, 92, 133, 0.15);

  &:focus {
    outline: 0;
    ${(p) => p.theme.focus}
  }
`;
