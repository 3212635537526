import { SubmissionType } from "generated/graphql";

import {
  lateAndOvertimeSubmission,
  lateDraftSubmission,
  lateFinalSubmission,
  lateResubmission,
  noLateAndOvertimeSubmission,
  noLateSubmission,
  noOvertimeSubmission,
  noSubmission,
  onTimeSubmission,
  overtimeSubmission,
  resubmitDraftSubmission,
  resubmitFinalSubmission,
  submitDraftSubmission,
  submitFinalSubmission,
} from "./submissionTexts";

/**
 * A possible Submission type with messaging fields describing what kind of
 * submission this option describes.
 *
 * This type encodes first submission, re-submissions, and late submission
 * options for a Draft and Final submission.
 */
export interface SubmissionOption {
  type: SubmissionType;
  text: string;
  subtext: React.ReactNode;
}

/**
 * Tagged Union representing different submission scenarios. Each scenario will
 * have 0 to 2 allowed submissions.
 *
 * A value for this type is computed using {@link createAllowedSubmissions}.
 *
 * The discriminating field for all union members is `option`.
 */
export type AllowedSubmissions =
  | NoAllowedSubmissions
  | OneAllowedSubmissions
  | BothAllowedSubmissions;

/** No submissions are allowed. */
export type NoAllowedSubmissions = {
  option: "none";
  title: string;
  subtext: string;
};

/**
 * Only 1 type of submission is allowed. Almost everytime this is the Final
 * submission.
 */
type OneAllowedSubmissions = {
  option: "one";
  submission: SubmissionOption;
};

/** Both Draft and Final submissions are allowed. */
type BothAllowedSubmissions = {
  option: "both";
  draft: SubmissionOption;
  final: SubmissionOption;
};

/**
 * Attribtues used to compute the currently allowed submissions,
 * i.e. `AllowedSubmissions` value.
 */
export interface CreateAllowedSubmissionsAttrs {
  /** Released Instruction draft due date is set. */
  hasDraftDue: boolean;
  /** Released Instruction final due date is set. */
  hasDueDate: boolean;

  /** Whether a previous draft submission exists. */
  hasDraft: boolean;
  /** Whether a previous final submission exists. */
  hasFinal: boolean;

  /** Submission flags */
  lateDraft: boolean;
  lateFinal: boolean;
  overtime: boolean;

  /** Whether the student can re submit a final after the due date. */
  allowLateResubmissions: boolean;

  /**
   * Just on the time limit.
   * This will show a special due date submission text.
   */
  onTimeLimit: boolean;
}

/**
 * Computes currently allowed submissions and the best choice out of them as
 * a tuple.
 *
 * The `AllowedSubmissions` value can be used to display a toggle between the
 * allowed options.
 *
 * The second `SubmissionOption` value (if present) can be used to initialise
 * the state of the toggle selector.
 *
 * There are 3 distinct scenarios which maps directly to the return types.
 *
 *  1. No allowed submission types (`NoAllowedSubmissions`)
 *  2. 1 submission type is allowed, mostly Final (`OneAllowedSubmissions`)
 *  3. Both Draft and Final submission types are allowed. (`BothAllowedSubmissions`)
 *
 * Refer to `draftSubmissionOption` and `finalSubmissionOption` for more breakdown of
 *  submission options at different stages of an assessment.
 *
 * @param attrs - all assessment attributes needed to compute the allowed
 * submissions
 * @returns a tuple containing the value describing currently allowed
 * submissions and the best submission option to select out of them, if one can
 * be selected.
 */
export function createAllowedSubmissions(
  attrs: CreateAllowedSubmissionsAttrs
): [AllowedSubmissions, SubmissionOption | null] {
  const {
    allowLateResubmissions,
    hasDraftDue,
    hasDueDate,
    hasDraft,
    hasFinal,
    lateDraft,
    lateFinal,
    overtime,
    onTimeLimit,
  } = attrs;

  const draft = hasDraftDue ? draftSubmissionOption(lateDraft, hasDraft) : null;
  const final = hasDueDate
    ? finalSubmissionOption(
        lateFinal,
        overtime,
        hasFinal,
        onTimeLimit,
        allowLateResubmissions
      )
    : null;

  // Which submissions are allowed
  let allowed: AllowedSubmissions;

  // only one allowed submission
  if (!final && draft) {
    // only draft submission is allowed
    allowed = {
      option: "one",
      submission: draft,
    };
  } else if (final && !draft) {
    // only final submission is allowed
    allowed = {
      option: "one",
      submission: final,
    };
  } else if (final && draft) {
    // both draft and final submissions are allowed
    allowed = {
      option: "both",
      draft,
      final,
    };
  } else {
    allowed = noAllowedSubmissions(lateFinal, overtime);
  }

  // Out of the allowed ones, which is the best submission to make
  let selected: SubmissionOption | null;
  switch (allowed.option) {
    case "none":
      selected = null;
      break;
    case "one":
      selected = allowed.submission;
      break;
    case "both":
      selected = lateDraft ? allowed.final : allowed.draft;
      break;
  }

  return [allowed, selected];
}

// ------------------------- draft submission options -------------------------
/**
 * Allowed draft submission option.
 *
 * Scenario: Assessment with draft due
 *
 *            1        draft         2
 *    |-------x----------|-----------x------>
 *
 * Allowed submissions:
 *  1. Submit and resubmit draft
 *  2. If no draft submission, late draft submission
 *
 */
const draftSubmissionOption = (
  late: boolean,
  hasDraft: boolean
): SubmissionOption | null => {
  if (late) return lateDraftSnippet(hasDraft);
  return draftSnippet(hasDraft);
};

const draftSnippet = (hasDraft: boolean): SubmissionOption => {
  // re-submit draft
  if (hasDraft) return resubmitDraftSubmission;

  // submit draft
  return submitDraftSubmission;
};

const lateDraftSnippet = (hasDraft: boolean): SubmissionOption | null => {
  if (hasDraft) return null;

  // late draft
  return lateDraftSubmission;
};

// ------------------------- final submission options -------------------------

/**
 * Allowed final submission option.
 *
 * ### Scenario 1: Assessment with no time limit
 *
 *            1      final     2
 *    |-------x--------|-------x------>
 *
 * Allowed submissions:
 *  1. Submit and resubmit final
 *  2. If no final submission, late final submission
 *
 *
 * ### Scenario 2: Assessment with time limit
 *
 *    start   1      end(2)   3    final     4
 *    |-------x-------x-------x------|-------x------>
 *
 * Allowed submissions:
 *  1. Submit and resubmit final
 *  2. On time limit final submission
 *  3. If no submission, overtime final
 *  4. If no submission, late and overtime final
 *  5. Past due date resubmission, if allowed
 *
 * ### Scenario 3: Assessment with time limit (end after final)
 *
 *    start   1     final     2     end(3)   4
 *    |-------x-------|-------x------x-------x------>
 *
 * Allowed submissions:
 *  1. Submit and resubmit final
 *  2. If no submission, late final
 *  3. If no submission, late final
 *  4. If no submission, late and overtime final
 *
 */
const finalSubmissionOption = (
  late: boolean,
  overtime: boolean,
  hasFinal: boolean,
  onTimeLimit: boolean,
  allowLateResubmissions: boolean
): SubmissionOption | null => {
  if (onTimeLimit && !late) return onTimeSubmission;

  if (!late && !overtime) return finalSnippet(hasFinal);
  if (late && overtime)
    return lateFinalSnippet(hasFinal, late, overtime, allowLateResubmissions);
  if (overtime)
    return lateFinalSnippet(hasFinal, late, overtime, allowLateResubmissions);
  if (late)
    return lateFinalSnippet(hasFinal, late, overtime, allowLateResubmissions);

  return null;
};

const finalSnippet = (hasFinal: boolean): SubmissionOption => {
  // re-submit final
  if (hasFinal) return resubmitFinalSubmission;

  // submit final
  return submitFinalSubmission;
};

const lateFinalSnippet = (
  hasFinal: boolean,
  late: boolean,
  overtime: boolean,
  allowLateResubmissions: boolean
): SubmissionOption | null => {
  if (hasFinal && !allowLateResubmissions) return null;

  if (hasFinal && allowLateResubmissions) return lateResubmission;

  // late and overtime final
  if (late && overtime) return lateAndOvertimeSubmission;

  // overtime final
  if (overtime) return overtimeSubmission;

  // late final
  return lateFinalSubmission;
};

const noAllowedSubmissions = (
  lateFinal: boolean,
  overtime: boolean
): NoAllowedSubmissions => {
  // late and overtime
  if (lateFinal && overtime) return noLateAndOvertimeSubmission;

  // overtime
  if (overtime) return noOvertimeSubmission;

  // late
  if (lateFinal) return noLateSubmission;

  return noSubmission;
};
