import { styled } from "styled-components";

import { Modal, SectionLabel, Spacer, Text } from "@vericus/cadmus-ui";

import NiceModal, { useModal } from "@ebay/nice-modal-react";

import { useAppSelector } from "@/data/hooks";
import { selectIsExam } from "@/features/assignment";
import { selectHasAcceptedSubmissionDeclaration } from "@/features/authority";

import { AcademicIntegrityAgreementProps } from "./AcademicIntegrityAgreement";
import { InstitutionAIAgreement } from "./InstitutionAIAgreement";

export interface AcademicIntegrityModalProps
  extends Pick<
    AcademicIntegrityAgreementProps,
    "isChecked" | "onUpdateAgreement" | "isExam"
  > {
  open: boolean;
  onExitComplete?: VoidFunction;
}

export const AcademicIntegrityModal = (
  agreementProps: AcademicIntegrityModalProps
) => {
  return (
    <Modal.Root open={agreementProps.open}>
      <Modal.Content
        withCloseButton={false}
        width={636}
        onExitComplete={agreementProps.onExitComplete}
      >
        <Container>
          <Text kind="headingOne" textAlign="center">
            Unable to submit
          </Text>
          <Spacer spacing={48} />
          <SectionLabel title="Academic integrity agreement" />
          <Spacer spacing={27} />
          <InstitutionAIAgreement
            {...agreementProps}
            showError={!agreementProps.isChecked}
          />
        </Container>
      </Modal.Content>
    </Modal.Root>
  );
};

export interface ConnectedAcademicIntegrityModalProps {
  onAgree: VoidFunction;
}

/**
 * A blocking modal that will only
 */
export const ConnectedAcademicIntegrityModal =
  NiceModal.create<ConnectedAcademicIntegrityModalProps>(({ onAgree }) => {
    const isExam = useAppSelector(selectIsExam);
    const hasAcceptedSubmissionDeclaration = useAppSelector(
      selectHasAcceptedSubmissionDeclaration
    );
    const modal = useModal();

    return (
      <AcademicIntegrityModal
        open={modal.visible}
        onExitComplete={modal.remove}
        isExam={isExam}
        isChecked={hasAcceptedSubmissionDeclaration}
        onUpdateAgreement={(hasAgreed: boolean) => {
          if (hasAgreed) {
            onAgree();
          }
        }}
      />
    );
  });

const Container = styled.div`
  padding: 76px 88px;
`;
