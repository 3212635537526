import { styled } from "styled-components";

import { Alert, Button, Modal, Text } from "@vericus/cadmus-ui";

import NiceModal, { useModal } from "@ebay/nice-modal-react";

import { LoadingQuill } from "@/components/loading-quill";
import { useAppDispatch, useAppSelector } from "@/data/hooks";
import {
  clearError,
  selectIsSubmitting,
  selectSubmitNetworkError,
  selectSubmitValidationError,
  SubmitError,
} from "@/features/authority";

interface Props {
  /**
   * Callback to manually retry the current submit request, if allowed.
   */
  onRetry?: () => void;

  /**
   * The modal is for an un-submission request.
   */
  isUnsubmit?: boolean;

  /**
   * The modal is triggered via an auto-submission.
   */
  autoSubmission?: boolean;
}

/**
 * Stateful Modal which tracks the submit async action.
 */
export const SubmitStatusModal = NiceModal.create((props: Props) => {
  const modal = useModal();

  const dispatch = useAppDispatch();
  const isSubmitting = useAppSelector(selectIsSubmitting);
  const networkErr = useAppSelector(selectSubmitNetworkError);
  const validationErr = useAppSelector(selectSubmitValidationError);
  const onDismissError = () => {
    dispatch(clearError());
    modal.hide();
  };

  return (
    <SubmitStatusModalContent
      {...props}
      visible={modal.visible}
      onExitComplete={modal.remove}
      isSubmitting={isSubmitting}
      hasNetworkError={!!networkErr}
      validationErr={validationErr}
      onDismissError={onDismissError}
    />
  );
});

// Inner modal props
interface ModalProps extends Props {
  /** Modal visibility state. */
  visible: boolean;
  onExitComplete?: VoidFunction;
  /** Submit request is in-flight. */
  isSubmitting: boolean;
  /**
   * The modal is triggered via an auto-submission.
   */
  autoSubmission?: boolean;
  /** Submit request has a network error. */
  hasNetworkError?: boolean;
  /** Submit request failed due to client-side validation */
  validationErr: SubmitError | null;
  /**
   * Callback to dismiss submission errors.
   */
  onDismissError?: () => void;
}

// Inner modal content
export function SubmitStatusModalContent(props: ModalProps) {
  const {
    onRetry,
    visible,
    onExitComplete,
    isSubmitting,
    autoSubmission = false,
    validationErr = null,
    hasNetworkError = false,
    isUnsubmit = false,
    onDismissError,
  } = props;

  const loadingMessage = isUnsubmit ? "Preparing..." : "Submitting...";

  let content = null;

  if (hasNetworkError && autoSubmission) {
    content = (
      <Root tabIndex={0}>
        <Text kind="headingOne">{"Time's"} up!</Text>
        <Alert severity="warning" title="Your writing time has ended">
          Please keep this window open. Your work will be auto submitted once
          you are reconnected to the internet. Contact your teacher if you’re
          unable to connect to the internet again.
        </Alert>
        <div>
          <SubmitButton
            kind="primary"
            size="lg"
            iconName="Send"
            iconPosition="right"
            disabled={!onRetry || isSubmitting}
            onClick={onRetry}
          >
            {!onRetry ? "Retrying submission..." : "Retry now"}
          </SubmitButton>
        </div>
      </Root>
    );
  } else if (hasNetworkError) {
    content = (
      <Root>
        <Text kind="headingOne">Unable to submit</Text>
        <Alert severity="error" title="Network disruption">
          Please check your internet connection. Your work will be submitted
          once you are reconnected to the internet.
        </Alert>
        <div>
          <SubmitButton
            kind="primary"
            size="lg"
            iconName="Send"
            iconPosition="right"
            disabled={!onRetry || isSubmitting}
            onClick={onRetry}
          >
            {!onRetry ? "Retrying submission..." : "Retry now"}
          </SubmitButton>
        </div>
      </Root>
    );
  } else if (validationErr) {
    content = (
      <Root>
        <Text kind="headingOne">Unable to submit</Text>
        <StyledAlert severity="warning" title={validationErr.title}>
          {validationErr.detail ?? "You must have 20 words or more to submit"}
        </StyledAlert>
        <div>
          {onDismissError && (
            <SubmitButton
              kind="primary"
              size="lg"
              disabled={isSubmitting}
              onClick={onDismissError}
            >
              Continue Working
            </SubmitButton>
          )}
        </div>
      </Root>
    );
  } else {
    content = (
      <Root tabIndex={0}>
        <LoadingQuill message={loadingMessage} />
      </Root>
    );
  }

  return (
    <Modal.Root open={visible}>
      <Modal.Content withCloseButton={false} onExitComplete={onExitComplete}>
        {content}
      </Modal.Content>
    </Modal.Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 32px;

  padding: 88px 48px;
  box-sizing: border-box;
  width: 636px;
  max-width: 98vw;
  max-height: 80vh;
  min-height: 438px;

  &:focus {
    outline: 0;
  }
`;

const SubmitButton = styled(Button)`
  width: 336px;
`;

const StyledAlert = styled(Alert)`
  min-width: 300px;
`;
