import { styled } from "styled-components";

import {
  CadmusEditorContent,
  Content,
  listFootnotes,
  useCadmusEditor,
} from "@vericus/cadmus-editor-prosemirror";
import { Divider, typography } from "@vericus/cadmus-ui";

import { __GLOBAL_TENANT } from "@/client/globals";

import { FootnoteContent } from "../editor";

interface Props {
  /** Prefix used to generate unique editor IDs */
  editorIdPrefix: string;
  /** Body editor contents */
  bodyContent?: Content;
  /** References editor contents */
  referencesContent?: Content;
  /** Optionally enable hanging indent in the references section */
  enableHangingIndent?: boolean;
}

const EMPTY_CONTENT = "";

export function SubmittedWork(props: Props) {
  const {
    editorIdPrefix,
    bodyContent,
    referencesContent,
    enableHangingIndent,
  } = props;

  const body = useCadmusEditor({
    editorId: `${editorIdPrefix}-body`,
    editorA11yLabel: "Assessment body (read-only)",
    content: bodyContent ?? EMPTY_CONTENT,
    editable: false,
    enableFootnoting: true,
  });

  const footnotes = body ? listFootnotes(body.state) : [];

  const references = useCadmusEditor({
    editorId: `${editorIdPrefix}-references`,
    editorA11yLabel: "Assessment references (read-only)",
    content: referencesContent ?? EMPTY_CONTENT,
    editable: false,
    enableHangingIndent,
  });

  return (
    <Container>
      <CadmusEditorContent editor={body} />
      {footnotes.length > 0 && (
        <>
          <Divider />
          <FootnoteContent footnotes={footnotes} />
        </>
      )}
      {referencesContent && (
        <>
          <Divider />
          <CadmusEditorContent editor={references} />
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  /* Sets displayTwo styles first child titles */
  .ProseMirror > h1:first-child {
    font-family: ${typography["displayTwo"].fontFamily};
    font-size: ${typography["displayTwo"].fontSize};
    font-weight: ${typography["displayTwo"].fontWeight};
    padding-top: ${typography["displayTwo"].paddingTop};
    padding-bottom: ${typography["displayTwo"].paddingBottom};
    line-height: ${typography["displayTwo"].lineHeight};
    letter-spacing: ${typography["displayTwo"].letterSpacing};
  }
`;
