import { styled } from "styled-components";

import { ParagraphSkeleton, Spacer, TitleSkeleton } from "@vericus/cadmus-ui";

import {
  BlockSnapshotFragment,
  TaskFormat,
  useDraftSubmissionQuery,
  useFinalSubmissionQuery,
} from "generated/graphql";
import { useSelector } from "react-redux";
import { SubmittedWork } from "ui/work/SubmittedWork";

import { AnswerPreview } from "@/features/answer";
import { selectAssessmentFormat } from "@/features/assignment";
import { useAnswerBlocksWithSnapshots } from "@/graphql/hooks";
import { parseSubmissionSnapshots } from "@/stores/snapshot";

// Props for SubmissionPane
interface Props {
  /** Work ID to fetch the latest submission for */
  workId: string;
  /** Hanging indent for rendering references in the submission */
  hangingIndent: boolean;
}

// Draft submission query wrapper for the <Submission /> component
export const DraftSubmissionPane = (props: Props) => {
  const { workId, hangingIndent } = props;

  const { data, loading } = useDraftSubmissionQuery({
    variables: { workId },
  });

  // Render a loading skeleton when the submission is being fetched and
  // initialised into editors
  if (!data || loading) {
    return <SubmissionSkeleton />;
  }

  // Render Answers instead of the Classic Work
  const hasBlockSnapshots =
    data.work?.submission?.blockSnapshots &&
    data.work.submission.blockSnapshots.length > 0;

  if (hasBlockSnapshots === true) {
    return (
      <SubmittedBlockSnapshots
        blockSnapshots={data.work.submission?.blockSnapshots ?? []}
      />
    );
  }

  const submission = data?.work?.submission;
  const snapshots = submission && parseSubmissionSnapshots(submission);

  // Render a loading skeleton when the submission is being fetched and
  // initialised into editors
  if (!snapshots) return <SubmissionSkeleton />;

  return (
    <SubmittedWork
      editorIdPrefix="draft"
      bodyContent={snapshots.body.answerDoc}
      referencesContent={snapshots.references.answerDoc}
      enableHangingIndent={hangingIndent}
    />
  );
};

// Final submission query wrapper for the <Submission /> component
export const FinalSubmissionPane = (props: Props) => {
  const { workId, hangingIndent } = props;
  const format = useSelector(selectAssessmentFormat);
  const { data, loading } = useFinalSubmissionQuery({
    variables: { workId },
  });

  // Render a loading skeleton when the submission is being fetched and
  // initialised into editors
  if (!data || loading) {
    return <SubmissionSkeleton />;
  }

  if (format === TaskFormat.Multiformat) {
    return (
      <SubmittedBlockSnapshots
        blockSnapshots={data.work.submission?.blockSnapshots ?? []}
      />
    );
  }

  const submission = data?.work?.submission;
  const snapshots = submission && parseSubmissionSnapshots(submission);

  return (
    <SubmittedWork
      editorIdPrefix="final"
      bodyContent={snapshots?.body.answerDoc}
      referencesContent={snapshots?.references.answerDoc}
      enableHangingIndent={hangingIndent}
    />
  );
};

interface SubmittedBlockSnapshotsProps {
  blockSnapshots: BlockSnapshotFragment[];
}

function SubmittedBlockSnapshots(props: SubmittedBlockSnapshotsProps) {
  const answerBlocks = useAnswerBlocksWithSnapshots(props.blockSnapshots);
  return <AnswerPreview answerBlocks={answerBlocks} showAlertOnUnAnswered />;
}

// Submission loading skeleton with title and paragraphs
const SubmissionSkeleton = () => (
  <Padded>
    <TitleSkeleton />
    <Spacer spacing={36} />
    <ParagraphSkeleton />
    <Spacer spacing={36} />
    <ParagraphSkeleton />
  </Padded>
);

const Padded = styled.div`
  padding: 63px 45px;
`;
