import { useManualPreset } from "@vericus/cadmus-manual";

import { createSelector } from "@reduxjs/toolkit";

import { useAppSelector } from "@/data/hooks";
import { selectAssignment } from "@/features/assignment";
import {
  selectHasSpecialConsideration,
  selectTimeline,
} from "@/features/timeline";
import { AssessmentType } from "@/generated/graphql";

import { Requirements } from "./Requirements";

interface Props {
  referencingStyle?: string;
  wordLimit?: number;
  countReferences?: boolean;
  weight?: number;
}

const selectTimelineDates = createSelector(selectTimeline, (timeline) => ({
  examOpenDate: timeline.examStartDate.date,
  examCloseDate: timeline.examEndDate.date,
  writingDate: timeline.writingDate.date,
  draftDate: timeline.draftDate.date,
  finalDate: timeline.finalDate.date,
  finalExtended: timeline.finalDate.extended,
}));

const selectRequirements = createSelector(selectAssignment, (assn) => ({
  assessmentType: assn.type,
  autoSubmission: assn.autoSubmission,
  examTiming: assn.examTiming,
  readingTime: assn.readingTime,
  writingTime: assn.writingTime,
  timeLimit: assn.timeLimit,
  timeLimitExtended: assn.timeLimitExtended,
}));

/**
 * Redux connected Requirements component.
 */
export function ConnectedRequirements(props: Props) {
  const openManualPreset = useManualPreset();

  const {
    examOpenDate,
    examCloseDate,
    writingDate,
    draftDate,
    finalDate,
    finalExtended,
  } = useAppSelector(selectTimelineDates);

  const {
    assessmentType,
    autoSubmission,
    examTiming,
    readingTime,
    writingTime,
    timeLimitExtended,
  } = useAppSelector(selectRequirements);

  const hasSpecialConsideration = useAppSelector(selectHasSpecialConsideration);

  if (assessmentType === AssessmentType.Exam) {
    return (
      <Requirements
        {...props}
        assessmentType={assessmentType}
        examTiming={examTiming}
        onOpenManualPreset={openManualPreset}
        examOpenDate={examOpenDate ?? undefined}
        examCloseDate={examCloseDate ?? undefined}
        writingDate={writingDate ?? undefined}
        readingTime={readingTime ?? undefined}
        writingTime={writingTime ?? undefined}
        autoSubmission={autoSubmission ?? undefined}
        hasSpecialConsideration={hasSpecialConsideration}
      />
    );
  }

  return (
    <Requirements
      {...props}
      assessmentType={assessmentType}
      onOpenManualPreset={openManualPreset}
      draftDate={draftDate ?? undefined}
      finalDate={finalDate ?? undefined}
      writingDate={writingDate ?? undefined}
      finalExtended={finalExtended}
      timeLimitExtended={timeLimitExtended}
      hasSpecialConsideration={false}
    />
  );
}
