import { combineReducers } from "@reduxjs/toolkit";

import { answerReducer } from "@/features/answer";
import { assignmentReducer } from "@/features/assignment";
import { authorityReducer } from "@/features/authority";
import { materialsReducer } from "@/features/materials";
import { timelineReducer } from "@/features/timeline";

// Create the root reducer independently to obtain the RootState type
export const rootReducer = combineReducers({
  assignment: assignmentReducer,
  timeline: timelineReducer,
  materials: materialsReducer,
  authority: authorityReducer,
  answer: answerReducer,
});
