import NiceModal from "@ebay/nice-modal-react";
import {
  isAsyncThunkAction,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";

import { ConnectedAcademicIntegrityModal } from "@/components/acadmic-integrity-agreement";
import type { AppStartListening } from "@/data/listenerMiddleware";
import {
  selectHasAutoSubmission,
  selectIsExam,
  selectIsTimedAssignment,
} from "@/features/assignment";
import {
  acceptSubmissionDeclaration,
  openSubmitPrompt,
  selectHasAcceptedSubmissionDeclaration,
  selectHasFinal,
  selectSubmitNetworkError,
  submit,
} from "@/features/authority";
import { SubmitStatusModal } from "@/features/submission";
import {
  examLateSubmissionDatePassed,
  writingDatePassed,
} from "@/features/timeline";
import { getCurrentDate } from "@/utils/datetime";
import { calculateDelayWithExponentialBackoffAndJitter } from "@/utils/jitter";

NiceModal.register("submit-status-modal", SubmitStatusModal);

/**
 * Start redux listeners related to the submission slice.
 */
export function startSubmitListeners(startListening: AppStartListening) {
  startTimedSubmissionListener(startListening);
  startSubmitActionListener(startListening);
}

/**
 * Listen for writing date to pass so that Auto Submissions or a Timed Submit
 * Modal can be displayed.
 */
function startTimedSubmissionListener(startListening: AppStartListening) {
  startListening({
    actionCreator: writingDatePassed,
    effect: async (_action, listenerApi) => {
      // Dismiss all toasts
      toast.dismiss();

      const state = listenerApi.getState();

      // Submissions should take the writing date
      // const now = getCurrentDate().toISOString();
      const submissionDate =
        state.timeline.writingDate.date || getCurrentDate().toISOString();

      const isExam = selectIsExam(state);
      const autoSubmission = selectHasAutoSubmission(state);

      // Exam Auto submissions
      if (isExam && autoSubmission) {
        if (selectHasAcceptedSubmissionDeclaration(state)) {
          await listenerApi.dispatch(
            submit({ submissionDate, autoSubmission: true })
          );
        } else {
          // Open modal so student can agree to submission declaration
          await NiceModal.show(ConnectedAcademicIntegrityModal, {
            onAgree: () => {
              listenerApi.dispatch(acceptSubmissionDeclaration());
              listenerApi.dispatch(submit({ submissionDate }));
              NiceModal.hide(ConnectedAcademicIntegrityModal);
            },
          });
        }
        return;
      }

      // Timed submit modal for an exam without auto submission.
      if (isExam && !autoSubmission) {
        listenerApi.dispatch(openSubmitPrompt(submissionDate));
        return;
      }

      // Timed submit modal for timed assignments.
      if (selectIsTimedAssignment(state)) {
        listenerApi.dispatch(openSubmitPrompt(submissionDate));
        return;
      }
    },
  });

  startListening({
    actionCreator: examLateSubmissionDatePassed,
    effect: async (_action, listenerApi) => {
      if (!selectHasFinal(listenerApi.getState())) {
        const now = getCurrentDate().toISOString();
        const submissionDate =
          listenerApi.getState().timeline.examLateSubmissionDate.date || now;
        listenerApi.dispatch(openSubmitPrompt(submissionDate));
      }
    },
  });
}

const isSubmitAction = isAsyncThunkAction(submit);

// Total number of submit request retries
export const MAX_ATTEMPTS = 10;

/**
 * Listen for the `submit` async action lifecycle.
 *
 * While the `submit` action is in in-flight, the Submit Status Modal tracks
 * it's loading state.
 *
 * On a submission error, the Submit Status Modal will display a error, while in
 * the background, we will retry the submission.
 */
function startSubmitActionListener(startListening: AppStartListening) {
  startListening({
    matcher: isSubmitAction,
    effect: async (action, listenerApi) => {
      let attempt = action.meta.arg?.attempt ?? 1;

      if (isPending(action)) {
        const freshRetry = () => {
          // if the user retries to re-submit again, the number of attempts start from 1 again
          listenerApi.dispatch(submit({ ...action.meta.arg, attempt: 1 }));
        };
        return await NiceModal.show("submit-status-modal", {
          // Let user to retry submitting only if all the attempts on the background have failed
          onRetry: attempt > MAX_ATTEMPTS ? freshRetry : undefined,
          autoSubmission: action.meta.arg?.autoSubmission ?? false,
        });
      }

      // Hide the modal if everything is good.
      if (isFulfilled(action)) {
        return await NiceModal.hide("submit-status-modal");
      }

      // Failed submission network errors should be retried
      if (
        isRejected(action) &&
        selectSubmitNetworkError(listenerApi.getState()) !== null
      ) {
        // Retry the exact same submission request by copying the args.
        const retrySubmitAfterDelay = async () => {
          // Artificially wait a bit inside the child

          const delayMs =
            calculateDelayWithExponentialBackoffAndJitter(attempt);
          await listenerApi.delay(delayMs);

          // If offline and the maximum number of attempts hasn't been reached yet (to avoid infinite recursion),
          // continue retrying without sending the request to the server
          if (!navigator.onLine && attempt <= MAX_ATTEMPTS) {
            attempt++;
            await retrySubmitAfterDelay();
          } else {
            await listenerApi.dispatch(
              submit({
                ...action.meta.arg,
                attempt: attempt + 1,
              })
            );
          }
        };

        if (attempt <= MAX_ATTEMPTS) {
          await retrySubmitAfterDelay();
        }
      }
    },
  });
}
