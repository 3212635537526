import { memo, useEffect, useState } from "react";

import { toast } from "react-hot-toast";

import { ToastContent } from "@/components/toaster";
import { formatMilliseconds, getCurrentDate } from "@/utils/datetime";

/**
 * Map of various timeline toasts.
 */
export const toasts = {
  reading: readingToast,
  writing: writingToast,
  submitReminder: submitReminderToast,
  lateSubmitReminder: lateSubmitReminderToast,
  submitCountdown: submitCountdownToast,
};

/**
 * Create a reading time start toast.
 *
 * @param readingDate ISO8601 Date string for the end of reading date.
 */
export function readingToast(readingDate: string) {
  const message = formatCountdownMessageFromNow(readingDate);
  return toast.success(
    <ToastContent
      message="Reading time has started"
      description={message ? `${message} remaining` : undefined}
    />,
    {
      id: "reading-time",
      icon: "📖",
    }
  );
}

interface WritingToastProps {
  // Writing date
  writingDate: string;
  isExam: boolean;
  // If the writing date is an extension, the number of minutes that it was
  // extended by.
  extendedBy?: number;
  // If the assessment is an exam
}

/**
 * Create a writing time start toast for the start of writing time or an
 * extension of the existing writing time.
 *
 * @param writingDate ISO8601 Date string for the end of writing date.
 */
function writingToast(props: WritingToastProps) {
  const { writingDate, isExam, extendedBy } = props;
  const message = formatCountdownMessageFromNow(writingDate);

  if (extendedBy) {
    let description = undefined;
    if (message) {
      const assessmentType = isExam ? "exam" : "assignment";
      description = `${message} remaining until the end of the ${assessmentType}`;
    }
    return toast.success(
      <ToastContent
        message={`You've been given ${extendedBy} minutes of extra time`}
        description={description}
      />,
      {
        id: "writing-time-extension",
        icon: "⏰",
      }
    );
  }

  return toast.success(
    <ToastContent
      message="You can start writing now"
      description={message ? `${message} remaining` : undefined}
    />,
    {
      id: "writing-time",
      icon: "👍",
    }
  );
}

interface SubmitReminderProps {
  submissionDate: string;
  autoSubmission: boolean;
}

/**
 * Create a submission reminder toast.
 */
function submitReminderToast(props: SubmitReminderProps) {
  const message = formatCountdownMessageFromNow(props.submissionDate);

  if (!message) {
    return null;
  }

  if (props.autoSubmission) {
    return toast.success(
      <ToastContent message={`${message} until your work is submitted`} />,
      {
        icon: "⏰",
        id: "submit-reminder",
      }
    );
  }

  return toast.success(
    <ToastContent
      message={`${message} remaining`}
      description="Get ready to submit your assessment"
    />,
    {
      icon: "⏰",
      id: "submit-reminder",
    }
  );
}

/**
 * Create an auto-submission countdown post.
 *
 * @param seconds - number of seconds to count down from.
 */
function submitCountdownToast(seconds: number) {
  return toast.loading(<SubmitCountdownContent seconds={seconds} />, {
    icon: "⏰",
    duration: Infinity,
    id: "submit-countdown",
  });
}

const SubmitCountdownContent = memo((props: { seconds: number }) => {
  const [remaining, setRemaining] = useState(props.seconds);

  useEffect(() => {
    if (remaining > 0) {
      const id = setTimeout(() => {
        setRemaining(remaining - 1);
      }, 1000);
      return () => clearTimeout(id);
    }
  }, [remaining, setRemaining]);

  const message =
    remaining > 0
      ? `Your work will be auto submitted in ${remaining} seconds`
      : "Submitting...";

  return <ToastContent message={message} />;
});

/**
 * Create a late submission reminder toast.
 */
function lateSubmitReminderToast() {
  return toast.success(
    <ToastContent message="1 minute remaining before the exam is closed" />,
    {
      icon: "⏰",
      id: "late-submit-reminder",
    }
  );
}

function formatCountdownMessageFromNow(dateStr: string): string | null {
  const now = getCurrentDate();
  const date = new Date(dateStr);
  if (date.getTime() > now.getTime()) {
    const diff = date.getTime() - now.getTime();
    return formatMilliseconds(diff);
  }
  return null;
}
