import { styled } from "styled-components";

import { QuestionPreviewNext } from "@vericus/cadmus-common";

import { QuestionType } from "@/generated/graphql";
import { useAnswerBlocks } from "@/graphql/hooks";

import { InstructionLabel } from "./styles";

/**
 * Render contents of the Overview question if there is one in an MultiFormat
 * assessment.
 */
export function Overview() {
  const answerBlocks = useAnswerBlocks();
  const overviewBlock = answerBlocks.find(
    (block) => block.question?.questionType === QuestionType.Overview
  );
  const overviewDoc = overviewBlock?.question?.body?.promptDoc ?? null;

  if (!overviewBlock || !overviewDoc) return null;

  return (
    <>
      <InstructionLabel title="Overview" />
      <QuestionContainer>
        <QuestionPreviewNext
          mode="question"
          data={null}
          questionId={overviewBlock.id}
          promptDoc={overviewDoc}
        />
      </QuestionContainer>
    </>
  );
}

const QuestionContainer = styled.div`
  max-width: 800px;
  margin: auto;
`;
