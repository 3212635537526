import { styled } from "styled-components";

import { Anchored, Button, Text } from "@vericus/cadmus-ui";

export interface ExamSurveyProps {
  href: string;
  onDismiss: VoidFunction;
}

export const ExamSurvey = ({ href, onDismiss }: ExamSurveyProps) => {
  return (
    <Container>
      <Text kind="bodyMd" bold>
        How was your experience using Cadmus in this exam?
      </Text>

      <Text kind="bodyMd" color="grey600">
        Thanks! Can you tell us more about how we can improve your experience?
      </Text>

      <Anchored href={href} newTab>
        <Button kind="secondary" onClick={onDismiss}>
          Take 2 min survey
        </Button>
      </Anchored>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
