import { Fragment } from "react";

import { Text } from "@vericus/cadmus-ui";

import { AnswerBlockFragment, QuestionType } from "@/generated/graphql";

import { AnswerPreviewItem } from "./answer-preview-item";
import * as styles from "./answer-preview.css";

interface AnswerPreviewProps {
  /**
   * Ordered Student answers. The content of the answer is in each Answer
   * Block's `latestBlockSnapshot` field.
   */
  answerBlocks: AnswerBlockFragment[];
  /** Assessment name rendered as the title */
  assessmentName?: string;
  /** Whether showing alert for unanswered question */
  showAlertOnUnAnswered?: boolean;
}

/** Read-only preview of all student answers. */
export function AnswerPreview(props: AnswerPreviewProps) {
  const answers = props.answerBlocks.filter((ans) => {
    const questionType = ans.question?.questionType;
    return (
      questionType !== QuestionType.Overview &&
      questionType !== QuestionType.Section
    );
  });

  return (
    <div className={styles.root}>
      {props.assessmentName && (
        <Text className={styles.assessmentTitle} kind="displayTwo">
          {props.assessmentName}
        </Text>
      )}
      {answers.map((answer, index, arr) => (
        <Fragment key={answer.id}>
          <AnswerPreviewItem
            answerBlock={answer}
            showAlertOnUnAnswered={props.showAlertOnUnAnswered}
            shuffle={answer.shuffle ?? undefined}
          />
          {index < arr.length - 1 && <div className={styles.divider} />}
        </Fragment>
      ))}
    </div>
  );
}
