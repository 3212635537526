import { Channel, Socket } from "phoenix";

import { WS_ENDPOINT } from "@/config";

/**
 * Wrapper for managing singular instances of Pantheon `Socket` and
 * Work `Channel`.
 *
 * An Aphrodite client can only connect to one authenticated Socket and
 * one Work channel (it's own).
 *
 * By default, the Socket and Channel are not initialised
 * automatically. Call `this.connect` manually once the Work ID
 * and User Token are known and available.
 */
export class PhoenixConnection {
  socket?: Socket;
  channel?: Channel;
  workId?: string;
  assessmentId?: string;

  /**
   * Initialise the authenticated Socket and Work channel
   * The socket is created only once, and the channel is joined only once.
   */
  connect = (
    workId: string,
    assessmentId: string,
    token: string,
    tenant: string
  ) => {
    const topic = `work:${workId}`;

    if (this.channel) {
      this.channel.leave();
      delete this.channel;
    }
    if (this.socket) {
      this.socket.disconnect();
      delete this.socket;
    }

    this.socket = new Socket(WS_ENDPOINT, {
      params: {
        token,
        role: "student",
        tenant,
        assessment_id: assessmentId,
        work_id: workId,
      },
    });

    this.socket.connect();
    this.socket.onClose((...args) => {
      console.info("Channel closed", args);
    });

    this.channel = this.socket.channel(topic);

    // Phoenix `Channel` objects have a joinedOnce property, not exposed to the
    // types.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!(this.channel as any).joinedOnce) {
      this.channel.join();
    }

    this.workId = workId;
    this.assessmentId = assessmentId;
  };
}

/** Global uninitialised PhoenixConnection */
export const connection = new PhoenixConnection();
