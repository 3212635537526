import { Editor, editorWordCount } from "@vericus/cadmus-editor-prosemirror";

import { EditorId } from "@/data/types";

import {
  forAllEditors,
  getAnswerEditor,
  useAnswerEditor,
} from "./answer-store";

export const useBodyEditor = () => useAnswerEditor(EditorId.Body);

export const useNotesEditor = () => useAnswerEditor(EditorId.Notes);

export const useReferencesEditor = () => useAnswerEditor(EditorId.References);

/** Word count for the current focused editor. */
export const useTotalWordCount = (): number => {
  const body = useBodyEditor();
  const references = useReferencesEditor();
  return sumEditorWordCounts([body, references]);
};

/** Check if all editors are initialised. */
export const useEditorsLoaded = () => {
  const body = useBodyEditor();
  const notes = useNotesEditor();
  const references = useReferencesEditor();

  if (body && notes && references) return true;

  return false;
};

export function getTotalWordCount(): number {
  const body = getAnswerEditor(EditorId.Body);
  const references = getAnswerEditor(EditorId.References);
  return sumEditorWordCounts([body, references]);
}

/** Add up all the editor word counts. */
function sumEditorWordCounts(editors: (Editor | null)[]): number {
  return editors.reduce((acc, editor) => {
    if (editor) {
      const count = editorWordCount(editor);
      return (count?.total ?? 0) + acc;
    }
    return acc;
  }, 0);
}

/** Predicate to check if any editor has a pending save debounced. */
export function hasPendingSave(): boolean {
  let hasPendingSave = false;
  forAllEditors((editor: Editor) => {
    if (editor.storage.telemetry?.pendingSave ?? false) {
      hasPendingSave = true;
    }
  });
  return hasPendingSave;
}

/** Return a list of editors which have a pending save operation. */
export function pendingSaveEditors(): [Editor, string][] {
  const editorsWithPendingSave: [Editor, string][] = [];

  forAllEditors((editor, editorId) => {
    if (editor.storage.telemetry?.pendingSave ?? false) {
      editorsWithPendingSave.push([editor, editorId]);
    }
  });

  return editorsWithPendingSave;
}
