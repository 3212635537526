import { styled } from "styled-components";

import { Icon, Tabs } from "@vericus/cadmus-ui";

import { useAppDispatch, useAppSelector } from "@/data/hooks";
import { selectAssessmentFormat, selectIsExam } from "@/features/assignment";
import { selectHasDraft, selectHasFinal } from "@/features/authority";
import {
  selectActiveReadingDate,
  selectTimedWorkNotStarted,
} from "@/features/timeline";
import { TaskFormat } from "@/generated/graphql";

import {
  MaterialsTab,
  selectMaterialsState,
  setTab,
} from "../../materials-slice";

/**
 * Materials TabBar component which can display the choice of all the panes
 * which are rendered in the MaterialsPane (selected by the enum
 * `MaterialsTab`).
 *
 * The open state, selected tab, and callbacks are pulled from the
 * contextual Preferences.
 */
export const TabBar = () => {
  const dispatch = useAppDispatch();
  const hasDraft = useAppSelector(selectHasDraft);
  const hasFinal = useAppSelector(selectHasFinal);
  const { tab } = useAppSelector(selectMaterialsState);

  const format = useAppSelector(selectAssessmentFormat);
  const isExam = useAppSelector(selectIsExam);
  const isReadingTime = useAppSelector(selectActiveReadingDate) !== null;
  const notStarted = useAppSelector(selectTimedWorkNotStarted);

  const showNotes =
    format === TaskFormat.Multiformat
      ? !isExam
      : isExam
        ? !isReadingTime && !notStarted
        : true;
  const showDraft = isExam ? false : hasDraft || tab === MaterialsTab.Draft;
  const showFinal = isExam ? false : hasFinal || tab === MaterialsTab.Final;

  return (
    <TabBarInner
      tab={tab}
      onSelectTab={(tab) => dispatch(setTab(tab))}
      isMultiFormat={format === TaskFormat.Multiformat}
      showInstructions={!(format === TaskFormat.Multiformat && isExam)}
      showNotes={showNotes}
      showDraft={showDraft}
      showFinal={showFinal}
    />
  );
};

// Props for TabBarInner
export interface TabBarInnerProps {
  tab: MaterialsTab;
  onSelectTab: (tab: MaterialsTab) => void;
  showLogo?: boolean;
  isMultiFormat?: boolean;
  showInstructions?: boolean;
  showNotes?: boolean;
  showDraft?: boolean;
  showFinal?: boolean;
}

export function TabBarInner(props: TabBarInnerProps) {
  const {
    tab,
    onSelectTab,
    showLogo = true,
    isMultiFormat = false,
    showInstructions = false,
    showNotes = false,
    showDraft = false,
    showFinal = false,
  } = props;

  return (
    <TabContainer>
      {showLogo && (
        <LogoContainer>
          <Icon iconName="Cadmus" style={{ width: 32, height: 32 }} />
        </LogoContainer>
      )}
      <Tabs.Root value={tab}>
        <StyledTabsList>
          {showInstructions && (
            <StyledTabsTrigger
              kind="primary"
              onClick={() => onSelectTab(MaterialsTab.Instructions)}
              value={MaterialsTab.Instructions}
              aria-label="Open instructions tab"
              aria-controls={`materials-${MaterialsTab.Instructions}`}
            >
              {isMultiFormat ? "Assessment" : "Instructions"}
            </StyledTabsTrigger>
          )}
          {showNotes && (
            <StyledTabsTrigger
              kind="primary"
              onClick={() => onSelectTab(MaterialsTab.Notes)}
              value={MaterialsTab.Notes}
              aria-label="Open notes tab"
              aria-controls={`materials-${MaterialsTab.Notes}`}
            >
              Notes
            </StyledTabsTrigger>
          )}
          {showDraft && (
            <StyledTabsTrigger
              kind="primary"
              onClick={() => onSelectTab(MaterialsTab.Draft)}
              value={MaterialsTab.Draft}
              aria-label="Open draft submission tab"
              aria-controls={`materials-${MaterialsTab.Draft}`}
            >
              Draft
            </StyledTabsTrigger>
          )}
          {showFinal && (
            <StyledTabsTrigger
              kind="primary"
              onClick={() => onSelectTab(MaterialsTab.Final)}
              value={MaterialsTab.Final}
              aria-label="Open final submission tab"
              aria-controls={`materials-${MaterialsTab.Final}`}
            >
              Final
            </StyledTabsTrigger>
          )}
        </StyledTabsList>
      </Tabs.Root>
    </TabContainer>
  );
}

const StyledTabsList = styled(Tabs.List)`
  height: 100%;
  overflow: hidden;
`;

const StyledTabsTrigger = styled(Tabs.Trigger)`
  height: 100%;
`;

const LogoContainer = styled.div`
  width: 60px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TabContainer = styled.div`
  display: flex;
  height: 100%;
`;
