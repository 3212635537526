import { FootnoteInfo } from "@vericus/cadmus-editor-prosemirror";

import * as styles from "./footnote-content.css";

export function FootnoteContent(props: { footnotes: FootnoteInfo[] }) {
  const parser = new DOMParser();
  return (
    <div className={styles.container}>
      {props.footnotes.map((footnote) => {
        const content = parser.parseFromString(
          footnote.content ?? "",
          "text/html"
        );
        return (
          <div
            key={footnote.id}
            dangerouslySetInnerHTML={{ __html: content.body.innerHTML }}
            className={styles.footnote}
          />
        );
      })}
    </div>
  );
}
