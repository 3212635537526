import {
  CadmusEditorContent,
  useCadmusEditor,
} from "@vericus/cadmus-editor-prosemirror";
import { useManualPreset } from "@vericus/cadmus-manual";

import * as Sentry from "@sentry/react";

import { useNotesEditor } from "@/stores/answer-store";

export function NotesEditorContent(props: { hideManual?: boolean }) {
  const editor = useNotesEditor();
  const openManualPreset = useManualPreset();
  return (
    <Sentry.ErrorBoundary>
      <CadmusEditorContent
        editor={editor}
        onOpenManualPreset={props.hideManual ? undefined : openManualPreset}
      />
    </Sentry.ErrorBoundary>
  );
}

export function NotesEditorContentReadOnly() {
  const notes = useNotesEditor();
  const content = notes?.getHTML() ?? null;
  const editor = useCadmusEditor(
    {
      editorId: "notes-readonly",
      editorA11yLabel: "Assessment notes (read-only)",
      content,
      editable: false,
    },
    [content]
  );
  return (
    <CadmusEditorContent editor={editor} aria-label="Assessment notes editor" />
  );
}
