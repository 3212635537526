import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "@/data/store";
import { selectSnapshots } from "@/features/authority";
import { QuestionType } from "@/generated/graphql";
import { isSnapshotEmpty } from "@/stores/snapshot";

import { PageInfo } from "./answer-slice";

/** Select answer slice state. */
export const selectAnswerSlice = (state: RootState) => state.answer;

/** Select all the answer pages. */
export const selectPages = createSelector(
  selectAnswerSlice,
  (state) => state.pages
);

/**
 * Select currently viewed page.
 */
export const selectCurrentPage = createSelector(
  selectAnswerSlice,
  (answer): PageInfo => {
    const page = answer.pages[answer.currentPageIndex] || answer.pages[0];
    if (page) {
      return page;
    }
    return { page: "overview" };
  }
);

/**
 * Select all Answer Block Ids that are answerable but considered incomplete.
 */
export const selectIncompleteAnswerIds = createSelector(
  selectPages,
  selectSnapshots,
  (pages, snapshots): string[] => {
    const incomplete = [];

    for (const page of pages) {
      if (page.page !== "answer") {
        continue;
      }

      if (page.questionType === QuestionType.Sub) {
        const hasIncompleteChildren = pages.some((p) => {
          if (
            p.page === "answer" &&
            p.parentAnswerId === page.answerId &&
            p.questionType
          ) {
            const snapshot = snapshots[p.answerId];
            return isSnapshotEmpty(p.questionType, snapshot) === true;
          }
          return false;
        });
        if (hasIncompleteChildren) {
          incomplete.push(page.answerId);
        }
      }

      if (page.questionType) {
        const snapshot = snapshots[page.answerId];
        if (isSnapshotEmpty(page.questionType, snapshot) === true) {
          incomplete.push(page.answerId);
        }
      }
    }
    return incomplete;
  }
);
