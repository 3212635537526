import { styled } from "styled-components";

import { Text } from "@vericus/cadmus-ui";

interface DataBlockProps {
  data: string | React.ReactNode;
  imgSrc?: string;
  imgAlt?: string;
  info?: string;
}

/**
 * Common component used to display data.  Displays an image related
 * to the data, the data itself, and a supporting text.
 */
export const DataBlock = (props: DataBlockProps) => {
  const { imgSrc, imgAlt, data, info } = props;

  return (
    <Container>
      <DatImg src={imgSrc} alt={imgAlt} />
      <Text kind="headingTwo">{data}</Text>
      <InfoText kind="label">{info}</InfoText>
    </Container>
  );
};

const Container = styled.div<{ centerAlign?: boolean }>`
  text-align: center;
  min-width: 150px;
`;

const DatImg = styled.img`
  height: 36px;
`;

const InfoText = styled(Text)`
  text-transform: uppercase;
  font-weight: 600;
`;
