import { styled } from "styled-components";

import { SectionLabel } from "@vericus/cadmus-ui";

export const InstructionLabel = styled(SectionLabel)`
  width: 100%;
  max-width: 800px;
  padding: 0 45px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -27px;
`;
