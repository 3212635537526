import { configureStore } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";

import { setPendingSnapshot } from "@/features/authority";
import { startAnswerListeners } from "@/stores/listeners/answer-listeners";
import { startExamListeners } from "@/stores/listeners/exam-listeners";
import { startSnapshotQueueListener } from "@/stores/listeners/snapshot-queue-listener";
import { startSubmitListeners } from "@/stores/listeners/submit-listeners";
import { startTimelineListeners } from "@/stores/listeners/timeline-listeners";

import { listenerMiddleware, startAppListening } from "./listenerMiddleware";
import { rootReducer } from "./root-reducer";
import { setupDOMListeners } from "./setup-dom-listeners";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

// Attach listeners
startTimelineListeners(startAppListening);
startSubmitListeners(startAppListening);
startExamListeners(startAppListening);
startAnswerListeners(startAppListening);
startSnapshotQueueListener(startAppListening);

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [setPendingSnapshot.type],
        ignoredPaths: ["authority.snapshots", "authority.pendingSnapshots"],
      },
    }).prepend(listenerMiddleware.middleware),
  enhancers: (getDefaultEnhancers) =>
    getDefaultEnhancers().concat(sentryReduxEnhancer),
});

setupDOMListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
