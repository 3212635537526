import { useMemo } from "react";

import { __GLOBAL_TENANT } from "client/globals";
import { ResultFragment } from "generated/graphql";

import { API_ENDPOINT } from "@/config";

const HERA_API_URL = `${API_ENDPOINT}/api/hera`;

// Fields needed from a GraphQL Result
type ResultMeta = Pick<
  ResultFragment,
  "id" | "heraResultId" | "canvasResultUrl" | "moodleGraderUrl" | "grade"
>;

/**
 * Create a callback to launch into the give grading service the backend's Hera
 * proxy API.
 *
 * Hera is our Turnitin integration service.
 */
export function useHeraResultLaunch(
  result: ResultMeta,
  gradingService?: string
): VoidFunction | undefined {
  let isReady = result.heraResultId !== null;
  let endpoint = "result";

  if (gradingService === "speedgrader") {
    isReady = result.canvasResultUrl !== null;
    endpoint = "speedgrader";
  } else if (gradingService === "moodlegrader") {
    isReady = result.moodleGraderUrl !== null;
    endpoint = "moodlegrader";
  } else if (gradingService === "cadmusgrader") {
    isReady = result.grade !== null;
    endpoint = "cadmusgrader";
  }
  const resultId = result.id;

  return useMemo(() => {
    if (isReady) {
      const url = createHeraURL(resultId, endpoint);
      return () => {
        const win = window.open(url.href, "_blank");
        if (win) {
          win.focus();
        }
      };
    }
    return undefined;
  }, [resultId, isReady, endpoint]);
}

function createHeraURL(resultId: string, endpoint: string) {
  const url = new URL(`${HERA_API_URL}/${endpoint}`);
  url.searchParams.append("role", "student");
  url.searchParams.append("result_id", resultId);
  if (__GLOBAL_TENANT.current) {
    url.searchParams.append("tenant", __GLOBAL_TENANT.current);
  }
  return url;
}
