import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { onContinueExam } from "@/features/assignment";
import { submit } from "@/features/authority";
import { SubmissionType } from "@/generated/graphql";

/** Materials redux state */
export interface MaterialsState {
  /** Open state of the Materials pane */
  isOpen: boolean;
  /** Currently selected tab to render in the panes */
  tab: MaterialsTab;
}

/**
 * Various selectable tabs to determine the contents of the Materials panes.
 * Materials sit adjacent to the main Student Work.
 */
export enum MaterialsTab {
  Instructions = "instructions",
  Notes = "notes",
  Draft = "draft",
  Final = "final",
}

const initialState: MaterialsState = {
  isOpen: true,
  tab: MaterialsTab.Instructions,
};

export const materialsSlice = createSlice({
  name: "materials",
  initialState,
  reducers: {
    setTab: (state, action: PayloadAction<MaterialsTab>) => {
      state.tab = action.payload;
      state.isOpen = true;
    },
    open: (state) => {
      state.isOpen = true;
    },
    close: (state) => {
      state.isOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submit.fulfilled, (state, action) => {
      state.isOpen = true;
      if (action.meta.arg?.submissionType === SubmissionType.Draft) {
        state.tab = MaterialsTab.Draft;
      } else {
        state.tab = MaterialsTab.Final;
      }
    });

    builder.addCase(onContinueExam, (state) => {
      state.isOpen = true;
      state.tab = MaterialsTab.Instructions;
    });
  },
});

// Export Actions
export const { setTab, open, close } = materialsSlice.actions;

// Export Reducer
export const materialsReducer = materialsSlice.reducer;
