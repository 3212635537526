import { useCallback, useState } from "react";

import { useAppSelector } from "@/data/hooks";
import { selectIsLockDownExam } from "@/features/assignment";
import {
  AssessmentType,
  useExperienceQuery,
  useUpdateExperienceMutation,
} from "@/generated/graphql";
import { useGlobalParams } from "@/utils/use-global-params";

import { ExamRating } from "./ExamRating";
import { ExamSurvey } from "./ExamSurvey";
import { Rating } from "./Rating";
import { Survey } from "./Survey";

export function StudentExperience() {
  const { workId, tenant, assessmentId } = useGlobalParams();
  const [surveyLink, setSurveyLink] = useState("");
  const isLockDown = useAppSelector(selectIsLockDownExam);

  const { data } = useExperienceQuery({
    variables: { workId: workId, assessmentId: assessmentId },
  });
  const experience = data?.work?.experience;
  const subjectId = data?.assessment?.subjectId;
  const isExam = data?.work?.sheet?.assessmentType === AssessmentType.Exam;

  // Mutation for the latest experience survey results
  const [updateScoreMutation] = useUpdateExperienceMutation({
    optimisticResponse: ({ workId, score }) => ({
      updateExperience: {
        id: workId,
        score,
        active: false,
        __typename: "Survey",
      },
    }),
  });

  const onUpdateScore = useCallback(
    (score: number) => {
      const surveyBaseURL = `https://cadmus.io/survey/student/${
        isExam ? "exam-experience" : "experience"
      }`;
      updateScoreMutation({
        variables: { workId: workId, score },
      });
      setSurveyLink(
        `${surveyBaseURL}?wid=${workId}&institution=${tenant}&sid=${subjectId}&respondus=${
          isLockDown ? "true" : "false"
        }&score=${score}`
      );
    },
    [
      workId,
      updateScoreMutation,
      setSurveyLink,
      isExam,
      subjectId,
      tenant,
      isLockDown,
    ]
  );

  if (!showStudentExperienceForTenant(tenant)) return null;

  if (experience?.active) {
    return isExam ? (
      <ExamRating onUpdateScore={onUpdateScore} />
    ) : (
      <Rating onUpdateScore={onUpdateScore} />
    );
  }

  if (surveyLink !== "") {
    if (isExam) {
      return (
        <ExamSurvey href={surveyLink} onDismiss={() => setSurveyLink("")} />
      );
    }
    return <Survey href={surveyLink} onDismiss={() => setSurveyLink("")} />;
  }

  return null;
}

function showStudentExperienceForTenant(tenant: string): boolean {
  switch (tenant) {
    case "uob":
    case "uob_sandpit":
      return false;
    default:
      return true;
  }
}
