import { Anchored, Button, Spacer, Text } from "@vericus/cadmus-ui";

import NotFoundImg from "@/assets/illustrations/not-found.png";

import { Actions, Grid, GridContent, GridImg } from "./styles";

export function NoMatchError() {
  return (
    <Grid>
      <GridImg
        src={NotFoundImg}
        alt="An illustration of a man trying to read a map. He looks lost."
      />
      <GridContent>
        <Text kind="displayTwo">404: Page not found</Text>
        <Text kind="lead">
          The page you’re looking for doesn’t exist. Try accessing Cadmus again
          from your Learning Management System.
        </Text>
        <Spacer spacing={27} />
        <Actions>
          <Anchored
            newTab
            href="https://support.cadmus.io/students/404-page-not-found"
          >
            <Button kind="secondary">Learn more about this error</Button>
          </Anchored>
          <Anchored newTab href="https://support.cadmus.io/kb-tickets/new">
            <Button>Contact us for help</Button>
          </Anchored>
        </Actions>
        <Spacer spacing={72} />
      </GridContent>
    </Grid>
  );
}
