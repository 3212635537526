import { styled } from "styled-components";

import { colors, Divider, largeDesktop } from "@vericus/cadmus-ui";

export const ContentCol = styled.div`
  text-align: left;
  flex-grow: 2;
`;

export const ContentDivider = styled(Divider)`
  margin: 24px 0px;
`;

export const Illustration = styled.img`
  max-width: 268px;

  ${largeDesktop`
    max-width: 325px;
    display: dog;
  `}
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 24px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-basis: 40%;
  flex-grow: 1;
`;

export const ActionContainer = styled.div`
  background: ${colors.white200};
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 436px;
`;
