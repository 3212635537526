import type { AppStartListening } from "@/data/listenerMiddleware";

import { startTimelineDateListeners } from "./timeline-date-listeners";
import { startTimelineExtensionListener } from "./timeline-extension-listeners";
import { startTimelineToastListeners } from "./timeline-toast-listeners";

export function startTimelineListeners(startListening: AppStartListening) {
  startTimelineDateListeners(startListening);
  startTimelineToastListeners(startListening);
  startTimelineExtensionListener(startListening);
}
