/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useParams } from "react-router-dom";

/**
 * Read the expected global URL params for an Aphrodite page.
 *
 * All Aphrodite pages are rendered on the URL with `tenant`, `assessmentId`, and `workId`.
 */
export function useGlobalParams(): {
  tenant: string;
  assessmentId: string;
  workId: string;
} {
  const params = useParams<"workId" | "tenant" | "assessmentId">();
  return {
    tenant: params.tenant!,
    assessmentId: params.assessmentId!,
    workId: params.workId!,
  };
}
