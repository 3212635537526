import { styled } from "styled-components";

import { colors, Desk, Icon, Text } from "@vericus/cadmus-ui";

import { useAppSelector } from "@/data/hooks";

import { selectActiveReadingDate } from "../../timeline-slice";

/**
 * Redux connected ReadingBar component.
 */
export function ConnectedReadingBar() {
  const showReadingBar = useAppSelector(selectActiveReadingDate) !== null;

  if (showReadingBar) {
    return <ReadingBar />;
  }

  return null;
}

/**
 * Application top bar indicating reading time status.
 */
export function ReadingBar() {
  return (
    <Container role="status">
      <Icon iconName={"Eye"} />
      <Text kind="bodySm" color="inherit">
        Reading time
      </Text>
    </Container>
  );
}

const Container = styled(Desk.Shelf)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  position: relative;
  z-index: 10;

  background: ${colors.blue200};
  color: ${colors.blue800};
  fill: #5b6f98;
  box-shadow: inset 0px -1px 0px rgba(89, 115, 166, 0.27);
  border: none;

  ${Icon} {
    margin-top: 2px;
  }
`;
