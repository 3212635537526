import { useFragment, UseFragmentResult } from "@apollo/client";

import client from "@/client/apollo";
import {
  AnswerBlockFragment,
  AnswerBlockFragmentDoc,
  AssessmentFragment,
  AssessmentFragmentDoc,
  BlockSnapshotFragment,
  InstitutionFragment,
  InstitutionFragmentDoc,
  QuestionFragment,
  QuestionFragmentDoc,
  SessionFragmentDoc,
  UserFragment,
  UserFragmentDoc,
  WorkAnswerBlocksFragment,
  WorkAnswerBlocksFragmentDoc,
  WorkSubmissionMetadataFragment,
  WorkSubmissionMetadataFragmentDoc,
} from "@/generated/graphql";

/**
 * Select the current user in the apollo cache.
 */
export function useCurrentUser() {
  return useFragment<UserFragment>({
    from: "User:{}",
    fragment: UserFragmentDoc,
    fragmentName: "User",
  });
}

/**
 * Select a Question from the cache.
 */
export function useQuestion(questionId: string) {
  return useFragment<QuestionFragment>({
    from: {
      __typename: "Question",
      id: questionId,
    },
    fragment: QuestionFragmentDoc,
    fragmentName: "Question",
  });
}

/** List latest AnswerBlocks from the apollo cache. */
export function useAnswerBlocks(): AnswerBlockFragment[] {
  const { data, complete } = useFragment<WorkAnswerBlocksFragment>({
    from: `Work:{}`,
    fragment: WorkAnswerBlocksFragmentDoc,
    fragmentName: "WorkAnswerBlocks",
  });

  return complete ? data.answerBlocks : [];
}

/** Read a single Answer Block fragment. */
export function useAnswerBlock(
  answerBlockId: string
): UseFragmentResult<AnswerBlockFragment> {
  return useFragment<AnswerBlockFragment>({
    from: {
      id: answerBlockId,
      __typename: "AnswerBlock",
    },
    fragment: AnswerBlockFragmentDoc,
    fragmentName: "AnswerBlock",
  });
}

/**
 * Select the singleton Institution data.
 */
export function useInstitution() {
  return useFragment<InstitutionFragment>({
    from: `Institution:{}`,
    fragment: InstitutionFragmentDoc,
    fragmentName: "Institution",
  });
}

/**
 * Read the loaded Assessment.
 */
export function useAssessmentFragment(): UseFragmentResult<AssessmentFragment> {
  return useFragment<AssessmentFragment>({
    from: `Assessment:{}`,
    fragment: AssessmentFragmentDoc,
    fragmentName: "Assessment",
  });
}

export function useWorkSubmissionMetadata() {
  return useFragment<WorkSubmissionMetadataFragment>({
    from: `Work:{}`,
    fragment: WorkSubmissionMetadataFragmentDoc,
    fragmentName: "WorkSubmissionMetadata",
  });
}

/**
 * List all AnswerBlocks but with their latest snapshot replaced with the
 * matching snapshot from `snapshots`.
 *
 * If an AnswerBlock does not have a matching snapshot, its `latestSnapshot` is
 * set to null.
 *
 * Useful to match submitted Block Snapshots with the current list of Answer
 * Blocks loaded in cache.
 */
export function useAnswerBlocksWithSnapshots(
  snapshots: BlockSnapshotFragment[]
): AnswerBlockFragment[] {
  const answerBlocks = useAnswerBlocks();

  return answerBlocks.map((block) => {
    const snapshot = snapshots.find(
      (snapshot) => snapshot.answerBlockId === block.id
    );
    return { ...block, latestBlockSnapshot: snapshot ?? null };
  });
}
/**
 * Verification of the session.
 */
export const useSessionVerification = (assessmentId: string): boolean => {
  const data = client.readFragment({
    id: "Session:{}",
    fragment: SessionFragmentDoc,
    fragmentName: "Session",
    variables: {
      assessmentId: assessmentId,
    },
  });

  return data?.verified ?? false;
};
