import { Editor } from "@vericus/cadmus-editor-prosemirror";

import { makeVar, useReactiveVar } from "@apollo/client";

/** State for active answer Editor. */
export interface ActiveEditor {
  /** Task Block ID */
  id: string;
  /** Editor instance for the prompt input in the TaskBlock. */
  editor: Editor;
}

/** Reactive variable storing the current active editor, if any. */
export const activeEditorVar = makeVar<ActiveEditor | null>(null);

/** Hook to get the current focused editor and control it. */
export function useActiveEditor(): Editor | null {
  const activeEditor = useReactiveVar(activeEditorVar);
  const editor = activeEditor?.editor ?? null;
  return editor;
}

/** Set an editor to be the active prompt editor. */
export function setActiveEditor(id: string, editor: Editor) {
  const activeEditor = activeEditorVar();
  if (activeEditor?.id === id) return;
  activeEditorVar({ id, editor });
}

/** Clear current active prompt editor */
export function clearActiveEditor() {
  if (!activeEditorVar) return;
  activeEditorVar(null);
}
