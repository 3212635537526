import { Anchored, Button, Spacer, Text } from "@vericus/cadmus-ui";

import CrashImg from "@/assets/illustrations/crash.png";

import { Actions, Grid, GridContent, GridImg } from "./styles";

export function CrashError() {
  return (
    <Grid>
      <GridImg
        src={CrashImg}
        alt="An illustration of a woman opening a box, with a rocket launching out of it!"
      />
      <GridContent>
        <Text kind="displayTwo">Oops. Something went wrong</Text>
        <Text kind="lead">
          Our team has been notified of the issue. Cadmus saves regularly if{" "}
          {"it's"} connected to the internet, so your work is most likely safe{" "}
          <span role="img" aria-label="smile">
            🙂
          </span>
          . Please click the button below to reload.
        </Text>
        <Spacer spacing={27} />
        <Actions>
          <Button
            kind="secondary"
            onClick={() => {
              window.location.reload();
            }}
          >
            Reload Cadmus
          </Button>
          <Anchored newTab href="https://support.cadmus.io/kb-tickets/new">
            <Button>Contact us for help</Button>
          </Anchored>
        </Actions>
        <Spacer spacing={72} />
      </GridContent>
    </Grid>
  );
}
