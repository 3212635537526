import React from "react";
import { styled } from "styled-components";

import { Radio, Spacer, Text } from "@vericus/cadmus-ui";

import finalImg from "@/assets/illustrations/student-on-laptop-and-checkmark.png";
import draftImg from "@/assets/illustrations/student-scribing-notepad.png";
import { SubmissionType } from "@/generated/graphql";

import { SubmissionOption } from "./createAllowedSubmissions";

interface NoOptionProps {
  subtext: React.ReactNode;
}

/** Submission options selector when there is no available options. */
export const NoOption = (props: NoOptionProps) => {
  const { subtext } = props;
  return (
    <>
      <Illustration src={finalImg} alt="" draggable="false" />
      <Spacer spacing={36} />
      <Text kind="bodyMd" as="div">
        {subtext}
      </Text>
    </>
  );
};
/** Submission options selector when there is only 1 allowed option */
export const OneOption = (props: { submission: SubmissionOption }) => {
  const { subtext, type } = props.submission;
  return (
    <>
      <Illustration
        src={type === SubmissionType.Final ? finalImg : draftImg}
        alt=""
        draggable="false"
      />
      <Spacer spacing={36} />
      <Text kind="bodyMd" as="div">
        {subtext}
      </Text>
    </>
  );
};

interface BothOptionProps {
  final: SubmissionOption;
  draft: SubmissionOption;
  submission: SubmissionOption | null;
  setSubmission: (s: SubmissionOption) => void;
}

/** Submission options selector when both Final and Draft options are allowed. */
export const BothOption = (props: BothOptionProps) => {
  const { draft, final, submission, setSubmission } = props;
  return (
    <>
      <OptionContainer>
        <BaseOption
          text={draft.text}
          checked={submission?.type === SubmissionType.Draft}
          onClick={() => setSubmission(draft)}
          illustration={draftImg}
          illustrationAlt=""
        />
        <BaseOption
          text={final.text}
          checked={submission?.type === SubmissionType.Final}
          onClick={() => setSubmission(final)}
          illustration={finalImg}
          illustrationAlt=""
        />
      </OptionContainer>
      <Spacer spacing={36} />
      {submission && (
        <Text kind="bodyMd" as="div">
          {submission.subtext}
        </Text>
      )}
    </>
  );
};

const OptionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 18px;
`;

// ------------------------- Base Selectable Option -------------------------

interface BaseOptionProps {
  illustration: string;
  illustrationAlt: string;
  text: string;
  checked: boolean;
  onClick: () => void;
}

/**
 * A square radio component with illustration and a text description
 */
const BaseOption = (props: BaseOptionProps) => {
  const { illustration, illustrationAlt, text, checked, onClick } = props;
  return (
    <BaseOptionContainer selected={checked} onClick={onClick}>
      <OptionRadio
        checked={checked}
        aria-label="Submission option"
        onChange={() => undefined}
      />
      <Illustration
        width={214}
        src={illustration}
        alt={illustrationAlt}
        draggable="false"
      />
      <Text kind="headingThree" textAlign="center">
        {text}
      </Text>
    </BaseOptionContainer>
  );
};

const BaseOptionContainer = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;

  max-width: 360px;
  max-height: 360px;

  padding: 18px 18px 27px;
  box-sizing: border-box;

  border-width: 1px;
  border-style: ${(p) => (p.selected ? "solid" : "dashed")};
  border-color: ${(p) => (p.selected ? p.theme.text.main : p.theme.divider)};
  box-shadow: ${(p) =>
    p.selected ? `0px 0px 0px 1px ${p.theme.text.main}` : "none"};

  border-radius: 5px;

  cursor: pointer;
  position: relative;
`;

const OptionRadio = styled(Radio)`
  position: absolute;
  top: 9px;
  right: 9px;
  margin: 0;
`;

const Illustration = styled.img<{ width?: number }>`
  width: ${(p) => `${p.width ?? 290}px`};
  height: auto;
  box-sizing: border-box;
  object-fit: contain;
`;
