import { useEffect, useState } from "react";
import { styled } from "styled-components";

import { Button, Spacer, Text } from "@vericus/cadmus-ui";

import { isAfter } from "date-fns";
import { SubmissionType } from "generated/graphql";
import { isLate } from "utils/datetime";

import { InstitutionAIAgreement } from "@/components/acadmic-integrity-agreement";

import {
  createAllowedSubmissions,
  SubmissionOption,
} from "./createAllowedSubmissions";
import { BothOption, NoOption, OneOption } from "./Options";
import { Timeline } from "./Timeline";

interface SubmitActionProps {
  /** Whether the student can re submit a final after the due date. */
  allowLateResubmissions: boolean;
  /** Datetime to be used as submission timestamp */
  submissionDate: Date;
  /** Existing draft submission? */
  hasDraft: boolean;
  /** Existing final submission? */
  hasFinal: boolean;
  /** Instruction Sheet release Date */
  releaseDate: Date | null;
  /** Final due date */
  finalDueDate: Date | null;
  /** Draft due date */
  draftDueDate: Date | null;
  /* Work start date for a time limited assessment */
  startDate: Date | null;
  /** Instructions time limit for a time limited assessment */
  endDate: Date | null;
  hasAcceptedSubmissionDeclaration: boolean;
  /** Callback to cancel the submission */
  onCancel: () => void;
  /** Callback to confirm and make the submission */
  onSubmit: (t: SubmissionType) => void;
  /** Disable the submission with a message */
  disabledMessage?: string;
  /** Callback for when the student accepts or rejects the submission declaration. */
  onUpdateSubmissionDeclaration: (isAccepted: boolean) => void;
  /** Whether the Submission was triggered automatically at the time limit */
  isTimeLimitTriggered?: boolean;
}

/**
 * Displays submission options for student to choose from.
 *
 * There are 3 main scenarios
 *  1. 2 Options - draft and final can be submitted.
 *                    student get to choose which submission to make.
 *  2. 1 option - only one type of submission can be submitted.
 *                    student can only submit the allowed submission type.
 *  3. 0 option - no submissions are allowed. Student can't submit.
 *
 *
 * Also displays user declaration that must be checked before submission.
 */
export const SubmitAction = ({
  allowLateResubmissions,
  submissionDate,
  hasDraft,
  hasFinal,
  releaseDate,
  finalDueDate,
  draftDueDate,
  startDate,
  endDate,
  disabledMessage: parentDisabledMessage,
  hasAcceptedSubmissionDeclaration,
  onUpdateSubmissionDeclaration,
  onCancel,
  onSubmit,
  isTimeLimitTriggered,
}: SubmitActionProps) => {
  // Selected submission type from the currently allowed types of submissions
  const lateDraft = draftDueDate ? isLate(draftDueDate, submissionDate) : false;
  const lateFinal = finalDueDate ? isLate(finalDueDate, submissionDate) : false;
  const overtime = endDate !== null ? isAfter(submissionDate, endDate) : null;

  // Current allowed type of submission and its meta description
  const [allowed, bestOption] = createAllowedSubmissions({
    allowLateResubmissions,
    hasDraftDue: !!draftDueDate,
    hasDueDate: !!finalDueDate,
    hasDraft,
    hasFinal,
    lateDraft,
    lateFinal,
    overtime: overtime === true,
    onTimeLimit: !!isTimeLimitTriggered,
  });

  const [submissionOption, setSubmissionOption] =
    useState<SubmissionOption | null>(bestOption);

  useEffect(() => {
    setSubmissionOption(bestOption);
  }, [bestOption]);

  // Disabling submission
  let disabledMessage: string | undefined;
  if (parentDisabledMessage !== undefined) {
    disabledMessage = parentDisabledMessage;
  } else if (!hasAcceptedSubmissionDeclaration) {
    // The Academic Integrity Message component has it's own error messaging,
    // but the submit should be disabled
    disabledMessage = "";
  } else if (!submissionOption) {
    disabledMessage = "Sorry, submission isn't available at the moment";
  }
  const submitDisabled = disabledMessage !== undefined;

  // Components depending on number of submission options
  let title;
  let option;

  switch (allowed.option) {
    case "none":
      title = allowed.title;
      option = <NoOption subtext={allowed.subtext} />;
      break;
    case "one":
      title = allowed.submission.text;
      option = <OneOption submission={allowed.submission} />;
      break;
    case "both":
      title = "Choose a submission";
      option = (
        <BothOption
          draft={allowed.draft}
          final={allowed.final}
          submission={submissionOption}
          setSubmission={setSubmissionOption}
        />
      );
      break;
  }

  return (
    <Container>
      <div>
        <Spacer spacing={32} />
        <Text kind="displayTwo">{title}</Text>
        <Spacer spacing={16} />
        {releaseDate && finalDueDate && (
          <Timeline
            submissionDate={submissionDate}
            releasedDate={releaseDate}
            draftDue={draftDueDate}
            dueDate={finalDueDate}
            draft={draftDueDate ? hasDraft : undefined}
            final={hasFinal}
            startDate={startDate}
            endDate={endDate}
          />
        )}
        <Spacer spacing={16} />
        {option}
        <Spacer spacing={16} />
      </div>
      <div>
        <InstitutionAIAgreement
          isChecked={hasAcceptedSubmissionDeclaration}
          onUpdateAgreement={onUpdateSubmissionDeclaration}
          showError={!hasAcceptedSubmissionDeclaration}
        />
        <Spacer spacing={16} />
        <CancelButton onClick={onCancel} style={{ marginRight: 18 }}>
          Cancel
        </CancelButton>
        {allowed.option !== "none" && (
          <>
            <SubmitButton
              disabled={submitDisabled}
              onClick={() =>
                submissionOption && onSubmit(submissionOption.type)
              }
              kind="secondary"
              iconName="Send"
              iconPosition="right"
            >
              {submissionOption?.text || "Submit"}
            </SubmitButton>
            {submitDisabled && (
              <ErrorMessage kind="systemSm" aria-live="polite">
                {disabledMessage}
              </ErrorMessage>
            )}
          </>
        )}
        <Spacer spacing={32} />
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  min-width: 320px;
  max-width: min(800px, 100%);
  padding: 0 45px;
  margin: auto;
  box-sizing: border-box;
  text-align: center;
`;

const CancelButton = styled(Button)`
  margin-bottom: 18px;
`;

const SubmitButton = styled(Button)`
  fill: white;
`;

const ErrorMessage = styled(Text)`
  color: ${(p) => p.theme.fail};
`;
