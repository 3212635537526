import { createSelector } from "@reduxjs/toolkit";
import { addMinutes, isAfter } from "date-fns";

import { RootState } from "@/data/store";
import {
  selectExamTotalTime,
  selectIsExam,
  selectIsLiveExam,
  selectIsTimedAssignment,
  selectIsWindowExam,
} from "@/features/assignment";

/** Select the `timeline` redux state slice. */
export const selectTimeline = (state: RootState) => state.timeline;

/** Select the `Date` when the live exam starts or window exam is open. */
export const selectExamStartDate = createSelector(
  selectTimeline,
  (timeline) => timeline.examStartDate.date
);

/** Select if the `examStartDate` has passed. */
export const selectExamStartDatePassed = (state: RootState) =>
  selectTimeline(state).examStartDate.passed;

/** Select the `Date` when the live exam ends or window exam should be closed. */
export const selectExamEndDate = createSelector(
  selectTimeline,
  (timeline) => timeline.examEndDate.date
);

/** Select if the `examEndDate` has passed. */
export const selectExamEndDatePassed = (state: RootState) =>
  selectTimeline(state).examEndDate.passed;

/**
 * Select if the reading date is in the past. If there is no reading date, then
 * it is considered to have passed.
 */
export const selectReadingDatePassed = createSelector(
  selectTimeline,
  (timeline) => {
    if (timeline.readingDate.date !== null) {
      return timeline.readingDate.passed;
    }
    return true;
  }
);

/** Select the Writing date for an exam or Timed Assignment. */
export const selectWritingDate = createSelector(
  selectTimeline,
  (timeline) => timeline.writingDate.date
);

/** Select if the writing date has passed. */
export const selectWritingDatePassed = createSelector(
  selectTimeline,
  (timeline) => timeline.writingDate.passed
);

/**
 * Select the FUTURE reading date if available and valid.
 */
export const selectActiveReadingDate = createSelector(
  selectTimeline,
  (timeline) => {
    if (
      timeline.examStartDate.passed === true &&
      timeline.readingDate.date !== null &&
      !timeline.readingDate.passed
    ) {
      return timeline.readingDate.date;
    }
    return null;
  }
);

/**
 * Select the FUTURE writing date if available and valid.
 */
export const selectActiveWritingDate = createSelector(
  selectIsExam,
  selectExamStartDatePassed,
  selectReadingDatePassed,
  selectWritingDatePassed,
  selectTimeline,
  (isExam, examStarted, readingTimeEnded, writingTimeEnded, timeline) => {
    if (
      isExam &&
      timeline.writingDate.date &&
      examStarted &&
      readingTimeEnded &&
      !writingTimeEnded
    ) {
      return timeline.writingDate.date;
    }

    if (!isExam && timeline.writingDate.date && !writingTimeEnded) {
      return timeline.writingDate.date;
    }
    return null;
  }
);

/**
 * Select if the exam requirements are from some special consideration
 * (extentions).
 */
export const selectHasSpecialConsideration = createSelector(
  (state: RootState) => state.timeline.examStartDate.extended,
  (state: RootState) => state.timeline.examEndDate.extended,
  (state: RootState) => state.timeline.readingDate.extended,
  (state: RootState) => state.timeline.writingDate.extended,
  (...flags) => flags.some(Boolean)
);

/**
 * Select if the current writing date is before the expected writing date.
 */
export const selectHasLimitedWritingTime = createSelector(
  selectTimeline,
  selectExamTotalTime,
  (timeline, totalTime) => {
    if (
      timeline.writingDate.date !== null &&
      timeline.workStartDate.date !== null
    ) {
      const expectedEnd = addMinutes(
        new Date(timeline.workStartDate.date),
        totalTime
      );
      if (isAfter(expectedEnd, new Date(timeline.writingDate.date))) {
        return true;
      }
    }
    return false;
  }
);

/**
 * Select if the Exam's late submission date has passed.
 *
 * If the date is not set (for non-exams and for unlimited late exam
 * submissions), the selector returns false.
 */
export const selectExamLateSubmissionDatePassed = createSelector(
  selectTimeline,
  (timeline) => timeline.examLateSubmissionDate.passed
);

/**
 * Select a FUTURE exam late submission date only if we are in the late
 * submission time window.
 */
export const selectActiveExamLateSubmissionDate = createSelector(
  selectIsExam,
  selectWritingDatePassed,
  selectExamLateSubmissionDatePassed,
  selectTimeline,
  (isExam, writingDatePassed, lateSubmitDatePassed, timeline) => {
    if (isExam && writingDatePassed && !lateSubmitDatePassed) {
      return timeline.examLateSubmissionDate.date;
    }
    return null;
  }
);

export const selectWorkStartDatePassed = (state: RootState) =>
  selectTimeline(state).workStartDate.passed;

/** Select the Draft submission due date. */
export const selectDraftDate = (state: RootState) =>
  selectTimeline(state).draftDate.date;

/** Select the Final submission due date. */
export const selectFinalDate = (state: RootState) =>
  selectTimeline(state).finalDate.date;

/** Select the Feedback return date. */
export const selectFeedbackDate = (state: RootState) =>
  selectTimeline(state).feedbackDate.date;

/** Select if the final due date has passed. */
export const selectFinalDatePassed = (state: RootState) =>
  selectTimeline(state).finalDate.passed;

/** Select if the Feedback return date has passed. */
export const selectFeedbackDatePassed = (state: RootState) =>
  selectTimeline(state).feedbackDate.passed;

/** Select if the final date is an extended due date. */
export const selectFinalDateExtended = (state: RootState) =>
  state.timeline.finalDate.extended;

/**
 * Select if the student has not elected to start working in a Timed assignment
 * or a Window exam.
 *
 * Returns true for a Live exam or a normal assignment.
 */
export const selectTimedWorkNotStarted = (state: RootState) => {
  if (selectIsTimedAssignment(state) || selectIsWindowExam(state)) {
    return selectTimeline(state).workStartDate.date === null;
  }

  if (selectIsLiveExam(state)) {
    return !selectExamStartDatePassed(state);
  }

  // Always started if the Assessment is not time limited or a windowed exam
  return true;
};
