import { Colored, Text } from "@vericus/cadmus-ui";

import draftImg from "@/assets/illustrations/student-with-tea.png";
import { formatDateSimple } from "@/utils/datetime";

import { Illustration } from "./styles";

interface Props {
  /** User email if available */
  email: string | null;

  /** Submission time */
  submittedAt: Date;

  /** Submission where submission time is after due date */
  late: boolean;
}

export function DraftConfirmation(props: Props) {
  const { submittedAt, late, email } = props;
  const submissionText = formatDateSimple(submittedAt);

  return (
    <>
      <Illustration
        src={draftImg}
        alt="Illustration of a student relaxing whilst listening to music and holding a cup of tea"
      />
      <Text kind="displayTwo" textAlign="center">
        You successfully <br /> submitted a draft
      </Text>
      <Text kind="bodyMd" textAlign="center">
        You submitted a draft on{" "}
        <strong>
          {submissionText}
          {late ? " (late)" : ""}
        </strong>
        {email && (
          <>
            {". We emailed confirmation of your submission to: "}
            <Colored color="shade1">
              <em>{email}</em>
            </Colored>
          </>
        )}
      </Text>
    </>
  );
}
