// server time singleton instance
import { API_ENDPOINT, DEV } from "@/config";

interface Time {
  (clientTime?: number): number;
}

interface ServerTimeInstance {
  sync: () => void;
  time: Time;
}

interface SyncInternals {
  getDiscrepancy: (
    lastTime: number,
    currTime: number,
    inverval: number
  ) => number;
  offset?: number;
  roundTripTime?: number;
  isSynced?: boolean;
}

function createServerTime(): ServerTimeInstance {
  // IE8 doesn't have Date.now()
  Date.now = Date.now || (() => +new Date());

  const syncUrl = `${API_ENDPOINT}/api/timesync`;
  const syncInterval = 60000;
  const maxAttempts = 5;
  let attempts = 0;
  let syncIntervalId: NodeJS.Timeout | null = null;
  let resyncIntervalId: NodeJS.Timeout | null = null;
  // Resync if unexpected change by more than a few seconds. This needs to be
  // somewhat lenient, or a CPU-intensive operation can trigger a re-sync even
  // when the offset is still accurate. In any case, we're not going to be
  // able to catch very small system-initiated NTP adjustments with this,
  // anyway.
  const tickCheckTolerance = 5000;

  let lastClientTime = Date.now();
  const syncInternals: SyncInternals = {
    getDiscrepancy: (lastTime: number, currentTime: number, interval: number) =>
      currentTime - (lastTime + interval),
    offset: undefined,
    roundTripTime: undefined,
    isSynced: false,
  };
  /**
   * update server time offset by calling a timesync endpoint
   */
  const updateServerOffset = () => {
    const t0 = Date.now();
    const updatePromise = fetch(syncUrl)
      .then((response) => {
        if (response.ok) {
          const t3 = Date.now();
          return response.text().then((text) => {
            const ts = parseInt(text, 10);
            const offset = Math.round((ts - t0 + (ts - t3)) / 2);
            const roundTripTime = t3 - t0;
            attempts = 0;
            syncInternals.offset = offset;
            syncInternals.roundTripTime = roundTripTime;
            syncInternals.isSynced = true;
          });
        }
        throw new Error("there is a network error");
      })
      .catch(() => {
        //  We'll still use our last computed offset if is defined
        attempts += 1;
        if (attempts <= maxAttempts) {
          setTimeout(resync, 1000);
        }
      });
    return updatePromise;
  };
  const resync = () => {
    if (resyncIntervalId !== null) {
      clearInterval(resyncIntervalId);
    }
    updateServerOffset();
    // set re-sync interval incase client think that
    // there isn't a discrepancy when there might be as server time
    // might drift as well
    resyncIntervalId = setInterval(updateServerOffset, 600000);
  };
  const sync = () => {
    if (syncIntervalId) {
      clearInterval(syncIntervalId);
    } else {
      resync();
    }
    syncIntervalId = setInterval(() => {
      const currentClientTime = Date.now();
      const discrepancy = syncInternals.getDiscrepancy(
        lastClientTime,
        currentClientTime,
        syncInterval
      );
      if (Math.abs(discrepancy) < tickCheckTolerance) {
        if (typeof syncInternals.offset === "undefined") {
          resync();
        }
      } else {
        if (syncInternals.offset) {
          syncInternals.offset -= discrepancy;
          syncInternals.isSynced = false;
        }
        resync();
      }
      lastClientTime = currentClientTime;
    }, syncInterval);
  };

  return {
    sync,
    time: (clientTime = Date.now()) => {
      if (syncInternals.offset) {
        return clientTime + syncInternals.offset;
      }
      return clientTime;
    },
  };
}

let time: Time;

if (DEV) {
  time = (clientTime = Date.now()) => clientTime;
} else {
  const serverTimeInstance: ServerTimeInstance = createServerTime();
  serverTimeInstance.sync();
  time = serverTimeInstance.time;
}

export { time };
