import type { AppStartListening } from "@/data/listenerMiddleware";
import { getExamWritingTime, updateSheet } from "@/features/assignment";
import { selectActiveWritingDate, toasts } from "@/features/timeline";

/**
 * Listen to `updateSheet` actions for emitting notifications on extensions or
 * special considerations granted to the student.
 */
export function startTimelineExtensionListener(
  startListening: AppStartListening
) {
  startWritingExtensionListener(startListening);
}

/**
 * Listen for writing time extensions and fire a toast notification if extra
 * time has been given to the student.
 */
export function startWritingExtensionListener(
  startListening: AppStartListening
) {
  startListening({
    predicate: (action, currState, prevState) => {
      if (updateSheet.match(action)) {
        const [_writingTime, extended] = getExamWritingTime(action.payload);
        const currWritingTime = currState.assignment.writingTime;
        const prevWritingTime = prevState.assignment.writingTime;
        if (
          extended &&
          currWritingTime &&
          prevWritingTime &&
          currWritingTime > prevWritingTime
        ) {
          return true;
        }
      }
      return false;
    },
    effect: (_action, listenerApi) => {
      const writingDate = selectActiveWritingDate(listenerApi.getState());
      if (writingDate) {
        const currWritingTime =
          listenerApi.getState().assignment.writingTime ?? 0;
        const prevWritingTime =
          listenerApi.getOriginalState().assignment.writingTime ?? 0;
        const extendedBy = currWritingTime - prevWritingTime;

        if (extendedBy > 0) {
          toasts.writing({
            writingDate,
            isExam: true,
            extendedBy,
          });
        }
      }
    },
  });
}
