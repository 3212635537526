import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import {
  __GLOBAL_ASSESSMENT_ID,
  __GLOBAL_AUTH_TOKEN,
  __GLOBAL_SESSION_ID,
  __GLOBAL_TENANT,
  __GLOBAL_WORK_ID,
} from "client/globals";
import { connection } from "client/phoenix";
import { createClient } from "graphql-ws";

import { API_ENDPOINT, WS_ENDPOINT } from "@/config";

// Link to set some Authorization token headers and other useful debugging
// headers on the requests sent to Pantheon, from the currently available global
// information.
const headersLink = setContext(() => {
  const headers: Record<string, string> = {};

  headers["x-cadmus-role"] = "student";
  headers["x-cadmus-tenant"] = __GLOBAL_TENANT.current ?? "";
  headers["x-cadmus-assessment"] = __GLOBAL_ASSESSMENT_ID.current ?? "";
  headers["x-cadmus-work"] = __GLOBAL_WORK_ID.current ?? "";
  headers["x-cadmus-url"] = window.location.href;

  if (__GLOBAL_AUTH_TOKEN.current) {
    headers["authorization"] = `Bearer: ${__GLOBAL_AUTH_TOKEN.current}`;
  }

  return {
    token: __GLOBAL_AUTH_TOKEN.current,
    headers,
  };
});

// Records and sets the Phoenix token by intercepting a GraphQL response which has a token.
// This also connects the global Phoenix "connection" with the parsed token.
const authLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((result) => {
    if (
      operation.operationName === "Work" &&
      operation.variables &&
      operation.variables.workId &&
      operation.variables.assessmentId &&
      result.data &&
      result.data.token &&
      __GLOBAL_TENANT.current
    ) {
      // WorkQuery returns a token, which can be stored globally
      __GLOBAL_AUTH_TOKEN.current = result.data.token;
      // Assign the global session ID from the response session for the WorkQuery.
      __GLOBAL_SESSION_ID.current = result.data.session.id || null;

      // Connect the global PhoenixConnection
      connection.connect(
        operation.variables.workId,
        operation.variables.assessmentId,
        result.data.token,
        __GLOBAL_TENANT.current
      );
    }

    return result;
  });
});

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Operation: ${operation.operationName} Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.error(`[Network error]: ${networkError}`);
});

// Apollo Link for Retry Logic on WorkDocument query
const workQueryRetryLink = new RetryLink({
  delay: {
    initial: Math.random() * 1500,
    max: 3000,
    jitter: true,
  },
  attempts: {
    max: 10,
    retryIf: (_error, operation) => {
      return operation.operationName === "Work";
    },
  },
});

// Main WS Link to the Pantheon GraphQL subscriptions websocket.
const wsLink = new GraphQLWsLink(
  createClient({
    url: `${WS_ENDPOINT}/graphql`,
    shouldRetry: () => true,
    connectionParams: () => {
      return {
        role: "student",
        token: __GLOBAL_AUTH_TOKEN.current,
        tenant: __GLOBAL_TENANT.current,
      };
    },
  })
);

// Main HTTP link to the Pantheon GraphQL API
const httpLink = new HttpLink({
  uri: `${API_ENDPOINT}/api/graphql`,
  credentials: "include",
});

// Terminating Apollo link which splits the Queries and Mutations (Pantheon HTTP
// API) from the Graphql Subscriptions (over Websocket)
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

// Global Apollo client for the app.
const client = new ApolloClient({
  cache: new InMemoryCache({
    possibleTypes: {
      QuestionResponse: ["MultiResponse", "EditorResponse", "BooleanResponse"],
    },
    typePolicies: {
      Institution: {
        // There is only a Singleton Institution
        keyFields: [],
      },
      Work: {
        // There is only a Singleton Work
        keyFields: [],
      },
      Assessment: {
        // There is only a Singleton Assessment
        keyFields: [],
      },
      User: {
        // There is only a Singleton User
        keyFields: [],
      },
      Submission: {
        keyFields: ["type"],
      },
      Session: {
        keyFields: [],
      },
      BlockSnapshot: {
        /**
         * We don't store multiple BlockSnapshots for
         * the same AnswerBlock x Type in the cache. This overrides
         * the existing cached BlockSnapshot (per AnswerBlock) with
         * the new one.
         */
        keyFields: ["cacheId"],
      },
    },
  }),
  link: from([headersLink, authLink, workQueryRetryLink, errorLink, splitLink]),
});

export default client;
